import BaseObject from '../BaseObject';

/**
 * @param {('noCoord'|'wgs84'|'rd'|'mercator'|'lambert1972'|'lambert2008')} value
 */
export default class AKGeoType extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapEnumData() {
        return {
            'noCoord'       : 'nocoord',
            'wgs84'         : 'wgs84',
            'rd'            : 'rd',
            'mercator'      : 'mercator',
            'lambert1972'   : 'lambert1972',
            'lambert2008'   : 'lambert2008'
        };
    }
}
