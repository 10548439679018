import BaseObject from '../BaseObject';
import AKVehicle from './AKVehicle';
import AKDriver from '../User/AKDriver';
import AKShiftState from './AKShiftState';
import AKLocationBase from '../Location/AKLocationBase';
import AKAAddShift from '../Admin/AKAAddShift';
import AKAAddShifts from '../Admin/AKAAddShifts';


export default class AKShift extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
        if (this.startTime >= this.endTime) this.raise('startTime should be smaller then endTime');
    }

    mapData() {
        return {
            'shiftId'       : ['ShiftId', 'integer'],
            'vehicle'       : ['Vehicle', AKVehicle],
            'driver'        : ['Driver', AKDriver, false],
            'startTime'     : ['StartTime', 'date'],
            'endTime'       : ['EndTime', 'date'],
            'state'         : ['ShiftState', AKShiftState],
            'startLocation' : ['StartLocation', AKLocationBase],
            'endLocation'   : ['EndLocation', AKLocationBase]
        };
    }


    get shiftTime() {
        return this.endTime.getTime() - this.startTime.getTime();
    }

    get plainObject() {
        var plainObj = {
            'startLocation': this.startLocation,
            'endLocation': this.endLocation,
            'startTime': this.startTime,
            'endTime': this.endTime,
            'vehicleType': this.vehicle.vehicleType,
            'licensePlate': this.vehicle.licensePlate
        };

        if (typeof this.driver != 'undefined') plainObj.driverId = this.driver.id;

        return plainObj;
    }

    get AKAAddShift() {
        return new AKAAddShift(this.plainObject);
    }

    get AKAAddShifts() {
        return new AKAAddShifts({shifts:[this.plainObject]});
    }
}
