import BaseObject from '../BaseObject';
import AKLocationBase from './AKLocationBase';

export default class AKArea extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'location'      : ['Location', AKLocationBase],
            'radiusMeter'   : ['RadiusMeter', 'integer'],
        };
    }
}
