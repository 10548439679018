import BaseService from './BaseService';

export default class AbelSecureService extends BaseService {
    constructor(AbelAdminKit) {
        super(AbelAdminKit, 'AbelSecureService');

        this.AbelAdminKit = AbelAdminKit;
    }

    // Update a customer account
    updateCustomer(data) {
        return this.call('UpdateCustomer', data);
    }

    // Change a customers password
    changePassword(oldPassword, newPassword) {
        return this.call('ChangePassword', { 'OldPassword': oldPassword, 'NewPassword': newPassword });
    }

    // Validate an email address
    validateEmail(validationToken) {
        return this.call('ValidateEmail', { 'ValidationToken': validationToken });
    }

    // Request a change of email address for the customer's account
    changeEmail(emailAdress) {
        return this.call('ChangeEmail', { 'Email': emailAdress });
    }

    // Get the customer account data
    getCustomer() {
        return this.call('GetCustomer');
    }

    // Share an update of push notification token and language preference with the server
    updateDevice(data) {
        return this.call('UpdateDevice', data);
    }

    // Get a list of all discounts added to the customer's account
    getDiscounts(id, paymentMethods = {}) {
        return this.call('GetDiscounts', { 'CustomerId': id, 'PaymentMethods': paymentMethods });
    }

    // Add a new discount to the customer's account
    addDiscount(data) {
        return this.call('AddDiscount', data);
    }

    // If a customer has booked a ride, this will return the details of the ride
    getActiveCustomerSchedule(activeTrips) {
        return this.call('GetActiveCustomerSchedule', { 'ActiveTrips': activeTrips });
    }

    // Get a list of payment methods that can be added to the account
    getAvailablePaymentMethods(data) {
        return this.call('GetAvailablePaymentMethods', data);
    }

    // Request a token to be able to communicate with the payment service provider
    getPaymentMethodToken(method) {
        return this.call('GetPaymentMethodToken', { 'Method': method });
    }

    // Add a new payment method
    addPaymentMethod(data) {
        return this.call('AddPaymentMethod', data);
    }

    // Get a list of all payment methods added to the customer's account
    getPaymentMethods(data) {
        return this.call('GetPaymentMethods', data);
    }

    // Remove a payment method from the customer's account
    removePaymentMethod(id) {
        return this.call('RemovePaymentMethod', id);
    }

    // Remove a payment method from the customer's account
    updatePaymentMethod(data) {
        return this.call('UpdatePaymentMethod', data);
    }

    // At the end of a ride the customer can rate a driver
    addDriverRating(data) {
        return this.call('AddDriverRating', data);
    }

    // Returns the trip tariffs
    tripTariff(data) {
        return this.call('TripTariff', data);
    }

    // Book a trip as customer
    bookTrip(data) {
        return this.call('BookTrip', data);
    }

    // Returns a list of trips the customer has requested
    getTrips(data = {}) {
        return this.call('GetTrips', data);
    }

    // Cancel a customer's trip if it has not been accepted by a driver yet
    cancelTrip(id) {
        return this.call('CancelTrip', id);
    }

    // Get possible time windows for a next booking
    availableTimeWindows(data) {
        return this.call('PossibleTimeWindows', data);
    }

    // Get possible time windows for a next booking
    deleteCustomer() {
        return this.deleteCall('DeleteCustomer');
    }
}
