import BaseObject from '../BaseObject';

/**
 * @param {number} companyId
 * @param {number} driverCardId
 * @param {string} email
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} tel
 * @param {string} tel2
 */
export default class AKADriver extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'companyId'     : ['CompanyId', 'integer'],
            'driverCardId'  : ['DriverCardId', 'integer'],
            'email'         : ['Email', 'string'],
            'firstName'     : ['FirstName', 'string'],
            'lastName'      : ['LastName', 'string'],
            'tel'           : ['Tel', 'string'],
            'tel2'          : ['Tel2', 'string']
        };
    }
}
