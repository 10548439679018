import BaseObject from '../BaseObject';

/**
 * @param {('address'|'airport'|'station'|'busStop'|'hospital'|'institution'|'poi'|'gps'|'alternativeEntrance'|'restaurant'|'postBox'|'homeAddress')} value
 */
export default class AKLocationType extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapEnumData() {
        return {
            'address'               : 'address',
            'airport'               : 'airport',
            'station'               : 'station',
            'busStop'               : 'busstop',
            'hospital'              : 'hospital',
            'institution'           : 'institution',
            'poi'                   : 'poi',
            'gpscoord'              : 'gpscoord',
            'alternativeEntrance'   : 'alternative_entrance',
            'restaurtant'           : 'restaurant',
            'postBox'               : 'postBox',
            'homeAddress'           : 'homeaddress'
        };
    }
}
