import BaseObject from '../BaseObject';

export default class AKShiftState extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapEnumData() {
        return {
            'active'                        : 'Active',
            'activeWithIntentToDeactivate'  : 'ActiveWithIntentToDeactivate',
            'inactive'                      : 'Inactive',
            'planned'                      : 'Planned'
        };
    }
}
