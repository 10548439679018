import BaseObject from '../BaseObject';

export default class AKVehicle extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'licensePlate'      : ['LicencePlate', 'string', false],
            'telephoneNumber'   : ['TelNr', 'string', false],
            'externalId'        : ['ExternId', 'string', false],
            'vehicleType'       : ['VehicleType', 'string', false]
        };
    }
}
