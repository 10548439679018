import BaseObject from '../BaseObject';

export default class AKTimeWindow extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'earliestTime'  : ['EarliestTime', 'date'],
            'latestTime'    : ['LatestTime', 'date']
        };
    }
}
