import BaseObject from '../BaseObject';

/**
 * @param {string} timezone
 */
export default class AKASettingsProfile extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'timezone'  : ['Timezone', 'string']
        };
    }
}
