import BaseObject from '../BaseObject';
import AKATripAttributes from './AKATripAttributes';
import AKLocationBase from '../Location/AKLocationBase';

/**
 * @param {AKATripAttributes} attributes
 * @param {number} customerId
 * @param {AKLocationBase} locationFrom
 * @param {AKLocationBase} locationTo
 */
export default class AKATripDetails extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'attributes'    : ['Attributes', AKATripAttributes],
            'customerId'    : ['CustomerId', 'integer'],
            'locationFrom'  : ['LocationFrom', AKLocationBase],
            'locationTo'    : ['LocationTo', AKLocationBase]
        };
    }
}
