import BaseObject from "../BaseObject";
import AKAMeetingPoint from "./AKAMeetingPoint";

export default class AKAMeetingPoints extends BaseObject {
  constructor(data = {}, hasServerSpecification = false) {
    super(data, hasServerSpecification);
  }

  mapData() {
    return {
      meetingpoints: ["Meetingpoints", [AKAMeetingPoint]]
    };
  }
}
