import Utils from './utils';
import AbelKitError from './AbelKitError';
import * as firebase from 'firebase';

import Request from '../components/request';
import Contracts from '../models/contracts';

/**
 * Firebase admin authentication.
 */

export default class AuthAdmin {
    constructor(admin, client_id = null) {
        this.user = admin;
        this.client_id = client_id;

        if (typeof firebase === 'undefined' || ! firebase) {
            throw new AbelKitError('Google Firebase library not initiated');
        }

        // Initialise Firebase
        firebase.initializeApp({
            apiKey: 'AIzaSyDZ_6d8DaSfsaqjzHb1QVfSIWIQgAigQZU',
            authDomain: 'brengflex-146811.firebaseapp.com',
            databaseURL: 'https://brengflex-146811.firebaseio.com',
            storageBucket: 'brengflex-146811.appspot.com',
        });
    }

    login(callback, errorCallback) {
        this.getValidToken(() => {
            callback(this.user);
        }, errorCallback);
    }

    logout(callback, errorCallback) {
        this.user.reset();

        return firebase
            .auth()
            .signOut()
            .then(() => {
                callback();
            })
            .catch(error => {
                errorCallback(error);
            });
    }

    getValidToken(callback, errorCallback) {
        let self = this;
        let now = new Date();
        let timestampFuture = now.setSeconds(now.getSeconds() + 5) / 1000;

        // Is the user already logged in and token is not (almost) expired, then directly return the user object
        if (this.user.is('loggedIn') && this.user.get('exp') >= timestampFuture && this.user.get('iat') < timestampFuture) {
            callback(this.user.get('token'));
            return;
        }

        // Is there already a token in the url? We can directly return the token string in the callback
        if (Utils.getUrlParameter('token')) {
            callback(Utils.getQueryString('token'));
        } else {
            // Token (almost) expired, get a new one from Google and middleware
            firebase.auth().getRedirectResult().then(() => {
                if (firebase.auth().currentUser === null) {
                    self.redirectToGoogleAuth();
                    return false;
                }

                // https://firebase.google.com/docs/reference/js/firebase.User#getToken
                firebase.auth().currentUser.getToken(true).then((googleToken) => {
                    this.loginToApi(googleToken, (data) => {
                        callback(data.token);
                    }, errorCallback);
                }).catch((error) => {
                    if (firebase.auth().currentUser === null) {
                        self.redirectToGoogleForAuth();
                        return false;
                    } else if (typeof errorCallback === 'function') {
                        errorCallback(error);
                    }
                });
            }).catch((error) => {
                errorCallback(error);
            });
        }
    }

    // ADMIN LOGIN (with firebase token)
    loginToApi(googleToken, callback, errorCallback) {
        let self = this;

        new Request(
            'AbelAdminService/Auth',
            { token: googleToken },
            response => {
                self.user.data = Utils.decodeWebToken(response.token);
                self.user.data.token = response.token;
                self.user.loggedIn = true;
                self.user.isAdmin = true;

                // Extract contracts
                self.user.data.contracts = new Contracts(response.contracts);

                if (typeof response.timezone !== 'undefined') {
                    self.user.data.timezone = response.timezone;
                } else {
                    self.user.data.timezone = 'UTC';
                }

                if (typeof callback === 'function') {
                    callback(self.user.data);
                }
            },
            errorCallback
        );
    }

    redirectToGoogleAuth() {
        let provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');

        return firebase.auth().signInWithRedirect(provider);
    }
}
