import BaseObject from '../BaseObject';

/**
 * @param {string} email
 * @param {string} name
 * @param {number[]} rights
 */
export default class AKAUser extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'email' : ['Email', 'string'],
            'name'  : ['Name', 'string'],
            'rights': ['Rights', ['integer']]
        };
    }
}
