import BaseObject from '../BaseObject';

/**
 * @param {string} emailSender
 * @param {string} emailSenderName
 * @param {string} mailchimpApiKey
 * @param {string} mailchimpCustomersList
 * @param {string} mailchimpDriversList
 * @param {string} zendeskAccount
 * @param {file} [emailImage]
 */
export default class AKASettingsEmail extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'emailSender'           : ['emailSender', 'string'],
            'emailSenderName'       : ['emailSenderName', 'string'],
            'mailchimpApiKey'       : ['mailchimpApiKey', 'string'],
            'mailchimpCustomersList': ['mailchimpCustomersList', 'string'],
            'mailchimpDriversList'  : ['mailchimpDriversList', 'string'],
            'zendeskAccount'        : ['zendeskAccount', 'string'],
            'emailImage'            : ['emailImage', 'file', false]
        };
    }
}
