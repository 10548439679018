import BaseObject from '../BaseObject';
import AKATripForecast from './AKATripForecast';


/**
 * @param {AKATripForecast} tripForecast
 */
export default class AKAGetTripForecastResponse extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'tripForecast'      : ['TripForecast', [AKATripForecast]]
        };
    }
}
