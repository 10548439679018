import BaseObject from '../BaseObject';
import AKShift from '../Shift/AKShift';

/**
 * @param {date} from
 * @param {date} til
 */
export default class AKAAddShiftResponse extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'shift'      : ['Shift', AKShift],
            'errorText'      : ['ErrorText', 'string'],
            'errorCode'      : ['ErrorCode', 'integer'],
            'serverTime'      : ['ServerTime', 'date']
        };
    }
}
