import BaseObject from '../BaseObject';
import AKXYCoord from '../Location/AKXYCoord';
import AKAMeetingPointLanguage from './AKAMeetingPointLanguage';
import AKAMeetingPointMetadata from './AKAMeetingPointMetadata';

export default class AKAMeetingPoint extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            id: ['Id', 'integer', false],
            position: ['Position', AKXYCoord],
            languages: ['Languages', [AKAMeetingPointLanguage]],
            metadata: ['Metadata', AKAMeetingPointMetadata],
            notavailable: ['NotAvailable', 'string']
        };
    }

    getLanguage(language) {
        return this.languages.find(i => {
            return i.language == language;
        });
    }
}
