import BaseObject from '../BaseObject';
import AKACustomerDetails from './AKACustomerDetails';

/**
 * @param {AKACustomerDetails} details
 * @param {string} language
 * @param {string} [mailchimp]
 * @param {number} [holdExportToCollectionAgencyUntil]
 */
export default class AKACustomer extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'details'                           : ['Customer', AKACustomerDetails],
            'language'                          : ['Language', 'string'],
            'holdExportToCollectionAgencyUntil' : ['HoldExportToCollectionAgencyUntil', 'integer', false],
            'mailchimp'                         : ['Mailchimp', 'boolean', false]
        };
    }
}
