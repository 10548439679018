import BaseObject from '../BaseObject';

/**
 * @param {string} password
 * @param {string} details
 */
export default class AKADriverDetails extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'password'  : ['Password', 'string'],
            'details'   : ['Driver', 'string']
        };
    }
}
