import BaseObject from '../BaseObject';
import AKXYCoord from '../Location/AKXYCoord';
import AKMeetingPoint from './AKMeetingPoint';

export default class AKAreaDetails extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'polygon'       : ['BoundingPolygon', [AKXYCoord]],
            'meetingPoints' : ['MeetingPoints', [AKMeetingPoint]]
        };
    }
}
