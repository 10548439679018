import BaseObject from '../BaseObject';
import AKLocationBase from '../Location/AKLocationBase';

/**
 * @param {number} radiusMeter
 * @param {AKLocationBase} location
 */
export default class AKAArea extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'radiusMeter'   : ['RadiusMeter', 'integer'],
            'location'      : ['Location', AKLocationBase]
        };
    }
}
