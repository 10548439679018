/**
 * The base of custom errors in ES6. Extend this class if you want to create a new error type.
 *
 * @see http://stackoverflow.com/a/32749533
 */
export default class ExtendableError extends Error {
    constructor(message) {
        super(message);

        this.message = message;
        this.name = this.constructor.name;

        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }
}
