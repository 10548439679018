import BaseObject from '../BaseObject';
import AKDiscountType from './AKDiscountType';
import AKArea from '../Location/AKArea';

export default class AKDiscount extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'discountId' : ['DiscountId', 'integer'],
            'discountType' : ['DiscountType', AKDiscountType],
            'description' : ['Description', 'string'],
            'validFrom' : ['ValidFrom', 'date', false],
            'validTil' : ['ValidTil', 'date', false],
            'originArea' : ['OriginArea', AKArea, false],
            'destinationArea' : ['DestinationArea', AKArea, false],
            'discountPercentage' : ['DiscountPercentage', 'float'],
            'maxDiscountEuro' : ['MaxDiscountEuro', 'float']
        };
    }
}
