export default class Contracts {
  constructor(data) {
    this.data = data;
    this.activeId = null;
    this.activeStorageKey = "activeContractId";

    // Set active contract id from localStorage
    let localStorageContractId = localStorage.getItem(this.activeStorageKey);
    this.setActive(
      localStorageContractId ? parseInt(localStorageContractId) : null
    );
  }

  get(id = null) {
    if (id === null) {
      // Return all contracts
      return this.data;
    } else {
      // Return contract by id
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === id) {
          return this.data[i];
        }
      }
    }

    return null;
  }

  getActive() {
    if (this.activeId != null) {
      return this.get(this.activeId);
    }

    return null;
  }

  getSettingForSelectedContract(setting) {
    return this.getActive().settings[setting];
  }

  set(data) {
    this.data = data;
  }

  setActive(id) {
    if (this.data.length === 0) {
      return false;
    }

    // Validate that user can indeed access the contract as stored in localStorage
    if (id && this.get(id) != null) {
      this.activeId = id;
    }

    // Default
    if (this.activeId == null) {
      this.activeId = this.data[0].id;
    }

    localStorage.setItem(this.activeStorageKey, this.activeId);
  }
}
