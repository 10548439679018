import BaseObject from '../BaseObject';

export default class AKAMeetingPointLanguage extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'language'      : ['Language', 'string'],
            'name'          : ['Name', 'string'],
            'description'   : ['Description', 'string']
        };
    }
}
