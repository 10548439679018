import BaseObject from '../BaseObject';
import AKXYCoord from '../Location/AKXYCoord';
import AKAMeetingPoint from './AKAMeetingPoint';

export default class AKAMeetingPointArea extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
        if (typeof this.meetingpoints == 'undefined') {
            this.meetingpoints = [];
        }
    }

    mapData() {
        return {
            'id'            : ['Id', 'integer', false],
            'path'          : ['BoundingPolygon', [AKXYCoord]],
            'meetingpoints' : ['MeetingPoints', [AKAMeetingPoint], false],
            'reference'     : ['ReferenceId', 'string', false]
        };
    }

    get latLngs() {
        return this.path.map((i) => {
            return {lat: i.y, lng: i.x};
        });
    }

}
