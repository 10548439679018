import BaseObject from '../BaseObject';

export default class AKDiscountType extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapEnumData() {
        return {
            'individual': 'individual',
            'marketing' : 'marketing'
        };
    }
}
