import AbelKitError from './AbelKitError';
import Promise from '../vendor/promise-polyfill';

/**
 * General utilities
 */

export default class Utils {
    static polyFills() {
        // Object.includes()
        if (! String.prototype.includes) {
            String.prototype.includes = function(search, start) {
                if (typeof start !== 'number') {
                    start = 0;
                }

                if (start + search.length > this.length) {
                    return false;
                } else {
                    return this.indexOf(search, start) !== -1;
                }
            };
        }

        // Constructor.name
        if (Function.prototype.name === undefined && Object.defineProperty !== undefined) {
            Object.defineProperty(Function.prototype, 'name', {
                get: function() {
                    let funcNameRegex = /function\s([^(]{1,})\(/;
                    let results = (funcNameRegex).exec((this).toString());

                    return (results && results.length > 1) ? results[1].trim() : '';
                },
                set: function(value) {}
            });
        }

        // Promises
        if (! window.Promise) {
            window.Promise = Promise;
        }
    }

    // Check whether parameter is in the GET query string
    static getUrlParameter(param) {
        let pageUrl = decodeURIComponent(window.location.search.substring(1));
        let urlVars = pageUrl.split('&');

        for (let i = 0; i < urlVars.length; i++) {
            let paramName = urlVars[i].split('=');

            if (paramName[0] === param) {
                return typeof paramName[1] === 'undefined' ? true : paramName[1];
            }
        }
        return false;
    }

    static decodeWebToken(token) {
        let segments = token.split('.');

        if (! segments instanceof Array || segments.length !== 3) {
            throw new AbelKitError('Invalid web token');
        }

        return JSON.parse(decodeURIComponent(escape(window.atob(segments[1]))));
    }

    static getQueryString(val) {
        let result = false;
        let tmp = [];

        location.search
            .substr(1)
            .split('&')
            .forEach((item) => {
                tmp = item.split('=');

                if (tmp[0] === val) {
                    result = decodeURIComponent(tmp[1]);
                }
            });

        return result;
    }
}
