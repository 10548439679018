import BaseObject from '../BaseObject';

/**
 * @param {('en'|'nl')} value
 */
export default class AKALanguage extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapEnumData() {
        return {
            'en'    : 'en',
            'nl'    : 'nl'
        };
    }
}
