import BaseObject from '../BaseObject';

/**
 * @param {string} driverAppMinimumversionIos
 * @param {string} driverAppMinimumversionAndroid
 * @param {string} customerAppMinimumversionIos
 * @param {string} customerAppMinimumversionAndroid
 * @param {file} [driverAppPushcertIos]
 * @param {file} [customerAppPushcertIos]
 * @param {string} driverAppCertPassphraseIos
 * @param {string} customerAppCertPassphraseIos
 * @param {string} appPushkeyAndroid
 */
export default class AKASettingsApps extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'driverAppMinimumversionIos'        : ['driverAppMinimumversionIos', 'string'],
            'driverAppMinimumversionAndroid'    : ['driverAppMinimumversionAndroid', 'string'],
            'customerAppMinimumversionIos'      : ['customerAppMinimumversionIos', 'string'],
            'customerAppMinimumversionAndroid'  : ['customerAppMinimumversionAndroid', 'string'],
            'driverAppPushcertIos'              : ['driverAppPushcertIos', 'file', false],
            'customerAppPushcertIos'            : ['customerAppPushcertIos', 'file', false],
            'driverAppCertPassphraseIos'        : ['driverAppCertPassphraseIos', 'string'],
            'customerAppCertPassphraseIos'      : ['customerAppCertPassphraseIos', 'string'],
            'appPushkeyAndroid'                 : ['appPushkeyAndroid', 'string']
        };
    }
}
