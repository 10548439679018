import BaseObject from '../BaseObject';
import AKLocationBase from '../Location/AKLocationBase';

/**
 * @param {date} from
 * @param {date} til
 */
export default class AKAAddShift extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'driverId'      : ['DriverId', 'integer', false],
            'startLocation' : ['StartLocation', AKLocationBase],
            'endLocation'   : ['EndLocation', AKLocationBase],
            'startTime'     : ['StartTime', 'date'],
            'endTime'       : ['EndTime', 'date'],
            'vehicleType'   : ['VehicleType', 'string', false],
            'licensePlate'  : ['LicencePlate', 'string', false]
        };
    }
}
