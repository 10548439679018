import BaseObject from '../BaseObject';

/**
 * @param {('adyen_creditcard'|'adyen_sepa_dd_ideal'|'braintree_paypal'|'braintree_creditcard')} value
 */
export default class AKAPaymentMethods extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapEnumData() {
        return {
            'adyen_creditcard'      : 'adyen_creditcard',
            'adyen_sepa_dd_ideal'   : 'adyen_sepa_dd_ideal',
            'braintree_paypal'      : 'braintree_paypal',
            'braintree_creditcard'  : 'braintree_creditcard'
        };
    }
}
