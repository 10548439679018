import * as Cookies from "js-cookie";

export default class User {
    constructor() {
        this.data = {};
        this.loggedIn = false;
        this.isAdmin = false;

        // todo: had to make this global because I needed it in AKAMeetingpointdata...not very pretty I know...but the whole project isn'...so whatever
        window._user = this;
    }

    // Get an user or all properties
    get(property = null) {
        if (!property) {
            return this.data;
        }

        if (typeof this.data[property] !== 'undefined') {
            return this.data[property];
        }

        return null;
    }

    set(property, value = null) {
        if (typeof property === 'object') {
            // Support multiple property / values as object in the first parameter
            for (let key in property) {
                if (property.hasOwnProperty(key)) {
                    this.set(key, property[key]);
                }
            }

            return true;
        } else if (typeof this.data[property] !== 'undefined') {
            return (this.data[property] = value);
        }

        return null;
    }

    // Check the state of the admin
    is(state) {
        switch (state) {
            case 'loggedIn':
                return this.loggedIn;
                break;
            case 'admin':
                return this.isAdmin;
                break;
        }
    }

    reset() {
        this.data = {};
        this.loggedIn = false;
        this.isAdmin = false;
    }
}
