import BaseObject from '../BaseObject';
import AKLocationBase from '../Location/AKLocationBase';

/**
 * @param {number} companyId
 * @param {string} email
 * @param {string} IBAN
 * @param {string} KvKNumber
 * @param {string} statutoryName
 * @param {AKLocationBase} statutoryAddress
 * @param {string} vatNumber
 */
export default class AKACompany extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'companyId'         : ['CompanyId', 'integer'],
            'email'             : ['Email', 'string'],
            'IBAN'              : ['IBAN', 'string'],
            'KvKNumber'         : ['KvKNumber', 'string'],
            'statutoryName'     : ['StatutoryName', 'string'],
            'statutoryAddress'  : ['StatutoryAddress', AKLocationBase],
            'vatNumber'         : ['VatNumber', 'string']
        };
    }
}
