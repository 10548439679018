import BaseObject from '../BaseObject';
import AKDriver from '../User/AKDriver';

/**
 * @param {date} from
 * @param {date} til
 */
export default class AKAGetDriversResponse extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'drivers'      : ['Drivers', [AKDriver]]
        };
    }
}
