import BaseObject from '../BaseObject';
import AKLocationBase from '../Location/AKLocationBase';

/**
 * @param {date} from
 * @param {date} til
 */
export default class AKADriverLogOn extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'driverId'      : ['DriverId', 'integer'],
            'startLocation' : ['StartLocation', AKLocationBase],
            'endLocation'   : ['EndLocation', AKLocationBase],
            'endTime'       : ['EndTime', 'date'],
            'licensePlate'  : ['LicencePlate', 'string']
        };
    }
}
