import Request from '../components/request';
import BaseObject from '../objects/BaseObject';


export default class BaseService {
    constructor(AbelAdminKit = null, serviceName = 'AbelService') {
        this.AbelAdminKit = AbelAdminKit;
        this.serviceName = serviceName;
    }

    callNoAuth(method, data = null, responseClass = null, requestType = null) {
        //let serviceName = this.constructor.name; // <-- This will fail when minimizing JS
        let serviceName = this.serviceName;

        if (data instanceof BaseObject) {
            data = data.transformToServerSpecification();
        }

        return new Promise((resolve, reject) => {
            let resolveAndConvert = (response) => {
                // Convert response to the given structure with objects
                if (responseClass !== null) {
                    response = new responseClass(response.return, true);
                }

                resolve(response);
            };

            const requestFailed = error => {
                reject(error);
            };

            if (this.AbelAdminKit) {
                this.AbelAdminKit.callNoAuth(`${serviceName}/${method}`, data, resolveAndConvert, requestFailed, requestType);
            } else {
                new Request(`${serviceName}/${method}`, data, resolveAndConvert, requestFailed, requestType);
            }
        });
    }

    // Make magic service call and return request wrapped in promise
    call(method, data = null, responseClass = null, requestType = null) {
        //let serviceName = this.constructor.name; // <-- This will fail when minimizing JS
        let serviceName = this.serviceName;

        if (data instanceof BaseObject) {
            data = data.transformToServerSpecification();
        }

        return new Promise((resolve, reject) => {
            let resolveAndConvert = (response) => {
                // Convert response to the given structure with objects
                try {
                    if (responseClass !== null) {
                        response = new responseClass(response.return, true);
                    }
                } catch (error) {
                    console.warn(error);
                }

                resolve(response);
            };

            const requestFailed = error => {
                reject(error);
            };

            if (this.AbelAdminKit) {
                this.AbelAdminKit.call(`${serviceName}/${method}`, data, resolveAndConvert, requestFailed, requestType);
            } else {
                new Request(`${serviceName}/${method}`, data, resolveAndConvert, requestFailed, requestType);
            }
        });
    }

    postCall(method, data = null, responseClass = null) {
        if (typeof data !== 'object' || data === null) {
            data = {};
        }

        data._requestMethod = 'post';

        return this.call(method, data, responseClass);
    }

    deleteCall(method, data = null, responseClass = null) {
        return this.call(method, data, responseClass, 'DELETE');
    }

    // Convert an object to a url friendly string query for searching
    urlQuery(parameters) {
        if (typeof parameters === 'object') {
            let query = '';
            for (let key in parameters) {
                if (parameters.hasOwnProperty(key)) {
                    query += `${key}:'${parameters[key]}' `;
                }
            }

            return query.trim();
        }

        return parameters;
    }

    // Convert an object to a url GET parameters string
    queryString(object) {
        let parts = [];

        for (let i in object) {
            if (object.hasOwnProperty(i)) {
                parts.push(`${encodeURIComponent(i)}=${encodeURIComponent(object[i])}`);
            }
        }

        return parts.join('&');
    }
}
