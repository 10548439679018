import BaseObject from '../BaseObject';

/**
 * @param {number} bookedSeatMinutes
 * @param {number} expectationOfBookedSeatMinutesAtTimeOfPickup
 * @param {date} startTime
 * @param {date} endTime
 * @param {number} availableSeatMinutes
 * @param {number} targetSeatUtilizationLowerBound
 * @param {number} targetSeatUtilizationUpperBound
 */
export default class AKATripForecast extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);

        this.expectationOfBookedSeatMinutesAtTimeOfPickup = Math.max(this.expectationOfBookedSeatMinutesAtTimeOfPickup, this.bookedSeatMinutes); //To ensure it is never smaller than bookedSeatMinutes
        this.availableSeatMinutes = 0;

        this.targetSeatUtilizationLowerBound = 0.28 * 3;
        this.targetSeatUtilizationUpperBound = 0.36 * 3;

    }

    mapData() {
        return {
            'bookedSeatMinutes'                             : ['BookedSeatMinutes', 'integer'],
            'expectationOfBookedSeatMinutesAtTimeOfPickup'  : ['ExpectationOfBookedSeatMinutesAtTimeOfPickup', 'integer'],
            'startTime'                                     : ['StartTime', 'date'],
            'endTime'                                       : ['EndTime', 'date']
        };
    }

    get currentSeatUtilization() {
        if (this.availableSeatMinutes > 0) {
            return this.bookedSeatMinutes/ this.availableSeatMinutes;
        } else {
            return 0;
        }
    }

    getCurrentSeatUtilizationString(decimals = 2) {
        if (this.availableSeatMinutes > 0) {
            return this.currentSeatUtilization.toFixed(decimals);
        } else {
            return 'N/A';
        }
    }

    get expectedSeatUtilization() {
        if (this.availableSeatMinutes > 0) {
            return this.expectationOfBookedSeatMinutesAtTimeOfPickup/ this.availableSeatMinutes;
        } else {
            return 0;
        }
    }

    getExpectedSeatUtilizationPercentage(decimals = 2) {
        if (this.availableSeatMinutes > 0) {
            return this.expectedSeatUtilization.toFixed(decimals);
        } else {
            return 'N/A';
        }
    }

    get performance() {
        if (this.expectedSeatUtilization < this.targetSeatUtilizationLowerBound) {
            return -1;
        } else if (this.expectedSeatUtilization > this.targetSeatUtilizationUpperBound) {
            return 1;
        } else {
            return 0;
        }
    }
}
