import ExtendableError from './ExtendableError';

export default class AbelKitError extends ExtendableError {
    constructor(message, code = null) {
        // Convert error string message to kit error object
        const messageObject = { type: 'AbelKitError', message: message, code: code };

        // Trigger global kit error event
        window.onAbelKitError(messageObject);

        super(messageObject);
    }
}
