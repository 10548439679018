import BaseObject from '../BaseObject';

export default class AKGPSPos extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'latitude'  : ['Latitude', 'float'],
            'longitude' : ['Longitude', 'float'],
            'timestamp' : ['Timestamp', 'date']
        };
    }
}
