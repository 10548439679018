import BaseObject from '../BaseObject';

/**
 * @param {string} email
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} tel
 */
export default class AKACustomerDetails extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'email'     : ['Email', 'string'],
            'firstName' : ['FirstName', 'string'],
            'lastName'  : ['LastName', 'string'],
            'tel'       : ['Tel', 'string']
        };
    }
}
