import BaseObject from '../BaseObject';
import AKXYCoord from '../Location/AKXYCoord';

export default class AKMeetingPoint extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'location'      : ['Location', AKXYCoord],
            'name'          : ['Name', 'string'],
            'description'   : ['Description', 'string']
        };
    }
}
