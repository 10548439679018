import BaseObject from '../BaseObject';
import AKAArea from './AKAArea';

/**
 * @param {string} description
 * @param {AKAArea} destinationArea
 * @param {number} discountPercentage
 * @param {string} discountType
 * @param {number} maxDiscountEuro
 * @param {AKAArea} originArea
 * @param {string[]} validFlexibilities
 * @param {date} validFrom
 * @param {date} validTil
 * @param {boolean} willBeInvoiced
 */
export default class AKADiscount extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'description'       : ['Description', 'string'],
            'destinationArea'   : ['DestinationArea', AKAArea],
            'discountPercentage': ['DiscountPercentage', 'integer'],
            'discountType'      : ['DiscountType', 'string'],
            'maxDiscountEuro'   : ['MaxDiscountEuro', 'float'],
            'originArea'        : ['OriginArea', AKAArea],
            'validFlexibilities': ['ValidFlexibilities', ['string']],
            'validFrom'         : ['ValidFrom', 'date'],
            'validTil'          : ['ValidTil', 'date'],
            'willBeInvoiced'    : ['WillBeInvoiced', 'boolean']
        };
    }
}
