import BaseObject from '../BaseObject';
import AKShift from '../Shift/AKShift';

/**
 * @param {date} from
 * @param {date} til
 */
export default class AKAGetShiftsResponse extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'shifts'      : ['Shifts', [AKShift]]
        };
    }
}
