export default class Request {
  /**
     * Make a POST or GET call to the middleware.
     *
     * @param {string} url
     * @param {Object} [data] - AJAX payload object.
     * @param {function} [successCallback] - A function called when the request finished with great success.
     * @param {function} [errorCallback] - A function called when something in the request went horribly wrong.
     */
  constructor(url, data, successCallback, errorCallback, requestType = null) {
    let xhr = new XMLHttpRequest();

    xhr.open(requestType ? requestType : this.getRequestType(url, data), this.getUrl(url), true);

    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    // Convert enum objects to literal values
    data = this.flattenObject(data);

    if (this.containsFile(data)) {
      // The data payload contains a file, so we need to change the way we do this call
      let formData = new FormData();

      // Replace data object with FormData
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }

      data = formData;
    } else {
      xhr.setRequestHeader("Content-Type", "application/json");
    }

    if (typeof window.onAbelKitPreRequest === "function") {
      const contractId = data.hasOwnProperty('ContractId') ? data.ContractId : null;

      if (contractId) {
          // remove contract id from payload
          delete data.ContractId;
      }

      window.onAbelKitPreRequest(xhr, url.indexOf('AbelAdminService') > -1, contractId);
  }

    xhr.responseType = "json";

    // Set the timeout of this call to 30 seconds
    let xhrTimeout = setTimeout(() => {
      xhr.abort();
    }, 30 * 1000);

    let callback = type => {
      clearTimeout(xhrTimeout);
      switch (type) {
        case "error":
          if (typeof errorCallback === "function") {
            let response = xhr.statusText;
            if (xhr.readyState !== 4) {
              response = "Request is not finished";
            } else if (xhr.status !== 200) {
              if (xhr.status === 0) {
                response = "Request failed to complete";
              } else {
                response = `Invalid response status: ${xhr.status}`;
              }
            }

            try {
              let errorData = xhr.response;

              if (
                typeof errorData === "string" &&
                errorData.length > 0 &&
                errorData[0] === "{"
              ) {
                // This is very likely to be a JSON string
                errorData = JSON.parse(errorData);
              }

              if (typeof errorData.result === "object") {
                errorCallback(
                  errorData.result.ErrorText || response,
                  errorData.result.ErrorCode || null
                );
              } else {
                errorCallback(response);
              }
            } catch (e) {
              errorCallback(response);
            }
          }
          break;

        default:
          let response = xhr.response;

          if (typeof xhr.response !== "object") {
            response = JSON.parse(response);
          }

          // Success
          if (typeof window.onAbelKitPostRequest === "function") {
            window.onAbelKitPostRequest(response);
          }

          if (typeof successCallback === "function") {
            successCallback(response);
          }
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        callback("success");
      } else {
        callback("error");
      }
    };

    xhr.ontimeout = xhr.onerror = () => {
      callback("error");
    };

    if (typeof xhr.onabort !== "undefined") {
      xhr.onabort = () => {
        callback("error");
      };
    }

    return xhr.send(
      typeof data === "object" && !(data instanceof FormData)
        ? JSON.stringify(data)
        : data
    );
  }

  /**
   * Return the server where we connect all our calls to.
   *
   * @returns {string} - The middleware server url.
   */
  static getServer() {

    if(Request.apiBaseUrl) {
        return Request.apiBaseUrl;
    }
    const ignoredPrefixes = ['www.', 'dev.'];
    let host = window.location.hostname;

    // strip off prefix
    if (ignoredPrefixes.indexOf(host.substr(0, 4)) > -1) {
      host = host.substr(4);
    }
    //@TODO - Refactor out API URL
    let stagingEnvironments = [
        'admin.rideabel.staging.vellance.net',
        'stg.tdreroute.com',
        'stg.rideplus.tdreroute.com',
        'stg.transdevlink.tdreroute.com',
        'stg.callcenter.tdreroute.com',
        'stg.callcenter.rideplus.tdreroute.com',
        'stg.callcenter.transdevlink.tdreroute.com',
        'stg.nicelink.tdreroute.com ',
        'stg.texelhopper.tdreroute.com',
        'stg.callcenter.texelhopper.tdreroute.com',
    ];

    if(stagingEnvironments.indexOf(host) !== -1) {
        return 'https://api.ptflex.stg.tdreroute.com/';
    }

    let acceptanceEnvironments = [
        'acc.tdreroute.com',
        'rideplus.acc.tdreroute.com',
        'translink.acc.tdreroute.com',
        'texelhopper.acc.tdreroute.com',
        'callcenter.acc.tdreroute.com',
        'callcenter.rideplus.acc.tdreroute.com',
        'callcenter.translink.acc.tdreroute.com',
        'callcenter.texelhopper.acc.tdreroute.com',
        'callcenter.nicelink.acc.tdreroute.com',
    ];

    if(acceptanceEnvironments.indexOf(host) !== -1) {
      return 'https://api.acc.tdreroute.com/';
    }

      switch (host) {
          case "admin.rideabel.accept.vellance.net":
          case "admin.accept.abel.taxi":
          case "web-booking.decoco.nl":
          case 'ptflex-dashboard.accept.reroute.pro':
          // OVFlex
          case "ovflex-booking.com":
          case "admin-ovflex-booking.com":
          case "staging.ovflex.app":
          case "staging.admin.ovflex.app":
          case "accept.tdreroute.com":
          case "accept.callcenter.tdreroute.com":
          //Texelhopper
          case "texelhopper-booking.com":
          case "admin.texelhopper-booking.com":
          case "staging.texelhopper.ovflex.app":
          case "staging.admin.texelhopper.ovflex.app":
          case "accept.callcenter.texelhopper.tdreroute.com":
          case "accept.texelhopper.tdreroute.com":
          // Ride plus
          case "book-ride-plus.com.au":
          case "callcenter.book-ride-plus.com.au":
          case "accept.rideplus.tdreroute.com":
          case "accept.callcenter.rideplus.tdreroute.com":
          case "rideplus-booking.com":
          case "admin.rideplus-booking.com":
          case "staging.rideplus.ovflex.app":
          case "staging.admin.rideplus.ovflex.app":
          // Transdev link
          case "book-transdevlink.com.au":
          case "callcenter.book-transdevlink.com.au":
          case "accept.transdevlink.tdreroute.com":
          case "accept.callcenter.transdevlink.tdreroute.com":
          case "translink-booking.com":
          case "admin-translink-booking.com":
          case "staging.translink.ovflex.app":
          case "staging.admin.translink.ovflex.app":
          // NICE
          case "admin.nicelink.tdreroute.com":
              return "https://api.ptflex.accept.tdreroute.com/";
          case "admin.rideabel.staging.vellance.net":
          case "admin.staging.abel.taxi":
          case "localhost":
              return "https://api.staging.abel.taxi/";
          case "admin.sandbox.hartlink.abel.taxi":
              return "https://api.sandbox.hartlink.abel.taxi/";
          case "admin.hartlink.abel.taxi":
              return "https://api.hartlink.abel.taxi/";
          case "admin.abel.taxi":
          case "admin.rideabel.com":
          case 'ptflex-dashboard.reroute.pro':
          // OVFlex
          case "overalflex.nl":
          case "callcenter.overalflex.nl":
          case "ovflex.nl":
          case "callcenter.ovflex.nl":
          // Texelhopper
          case "tickets.texelhopper.nl":
          case "callcenter.texelhopper.nl":
          // Ride plus
          case "book-ride-plus.com.au":
          case "callcenter.book-ride-plus.com.au":
          // Transdev link
          case "book-transdevlink.com.au":
          case "callcenter.book-transdevlink.com.au":
          // NICE
          case "admin.nicelink.ovflex.nl":
              return "https://gomobile.rideabel.com/";
              break;
          // AWS prod intermediate
          case "prd-bw-appelb-au-1377672714.ap-southeast-2.elb.amazonaws.com":
              return "https://api.rideabel.com/";
          case "ec2-34-243-167-65.eu-west-1.compute.amazonaws.com":
              return "http://ec2-52-18-15-233.eu-west-1.compute.amazonaws.com/" // aws acceptance
          case 'ptflex-dashboard.accept.reroute.pro.s3-website-us-east-1.amazonaws.com': //aws s3 accept
              return 'http://ptflex-dashboard.accept.reroute.pro.s3-website-us-east-1.amazonaws.com';
          case 'ptflex-dashboard.reroute.pro.s3-website-us-east-1.amazonaws.com': //aws s3 prod
              return 'http://ptflex-dashboard.reroute.pro.s3-website-us-east-1.amazonaws.com';
          case 'ptflex-dashboard.staging.reroute.pro':
              return 'http://api.ptflex.staging.reroute.pro';
          case 'admin.ptflex.net':
              return 'https://api.ptflex.net/';
          case "admin.rideabel.local":
          default:
              return "http://api.rideabel.local/app_dev.php/";
      }
  }

  /**
     * Convert internal request to the correct server url or leave an external request as is.
     *
     * @param {string} url
     * @returns {string} - The request url.
     */
  getUrl(url) {
    let requestUrl = url;

    if (/^(f|ht)tps?:\/\//i.test(url) === false) {
      requestUrl = this.constructor.getServer() + url;
    }

    return requestUrl;
  }

  /**
     * Automatically retrieve POST or GET request type depending on the method called.
     *
     * @param {string} url
     * @param {Object|null} [data]
     * @returns {string} - Request method type
     */
  getRequestType(url, data = null) {
    let urlParts = url.split("/");
    let firstPart = urlParts[0];
    let lastPart = urlParts[urlParts.length - 1];

    if (data && typeof data._requestMethod === "string") {
      let requestMethod = data._requestMethod.toUpperCase();

      // Don't include the request method in the call
      delete data._requestMethod;

      return requestMethod;
    } else if (firstPart === "AbelAdminService") {
      // AbelAdminService needs seperate request type checking
      if (!data) {
        // If there is no data or an empty object given, it has to be a GET request
        return "GET";
      }
    } else if (lastPart.substring(Math.max(lastPart.length - 2, 0)) === "get") {
      // Called method begins with "get"
      return "GET";
    }

    return "POST";
  }

  /**
     * Detect if there is a file in the payload, so we can change the type of call.
     *
     * @param {Object} data - AJAX payload object.
     */
  containsFile(data = {}) {
    for (let key in data) {
      if (typeof data[key] === "object" && data[key] instanceof File) {
        return true;
      }
    }

    return false;
  }

  /**
     * Find an enum object and convert it to its literal value in an AJAX payload object.
     *
     * @param {Object} object - AJAX payload object.
     */
  flattenObject(object) {
    let flatObject = {};

    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        if (object[key] === null) {
          continue;
        }

        if (typeof object[key] === "object") {
          // Check if this is an enum object
          if (
            object[key] &&
            typeof object[key]._isEnum !== "undefined" &&
            object[key]._isEnum === true
          ) {
            // Get the value of ".value", which is the literal value
            flatObject[key] = object[key].value;
          } else {
            // Recursive flatten
            flatObject[key] = this.flattenObject(object[key]);
          }
        } else {
          flatObject[key] = object[key];
        }
      }
    }

    return flatObject;
  }
}

Request.apiBaseUrl = null;