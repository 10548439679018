import BaseObject from '../BaseObject';

/**
 * @param {string} content
 * @param {string} subject
 */
export default class AKASettingsTemplates extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'content'   : ['Content', 'string'],
            'subject'   : ['Subject', 'string']
        };
    }
}
