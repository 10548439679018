import Utils from './utils';
import AbelKitError from './AbelKitError';

import * as Cookies from "js-cookie";

import Request from '../components/request';
/**
 * OAuth authentication.
 */

export default class Auth {
    constructor(user, client_id, client_secret) {
        this.user = user;
        this.client_id = client_id;
        this.client_secret = client_secret;
    }

    login(client_id, client_secret, username, password, callback, errorCallback) {
        let self = this;
        let grant_type = 'password';

        this.oAuthRequest(
            Request.getServer() + 'oauth/v2/token',
            {grant_type, client_id, client_secret, username, password},
            response => {
                let now = new Date();
                let expireTime = now.setSeconds(now.getSeconds() + 3600) / 1000;

                self.user.data = {
                    exp: expireTime,
                    token: response.access_token,
                    refresh_token: response.refresh_token,
                    customer: response.Customer,
                    timezone: 'UTC',
                    client_id: client_id,
                    client_secret: client_secret
                };
                self.user.loggedIn = true;

                Cookies.set('api_oauth_token', self.user.data.token);
                Cookies.set('api_token_expire', expireTime);
                Cookies.set('api_refresh_token', self.user.data.refresh_token);
                Cookies.set('api_client_id', client_id);
                Cookies.set('api_client_secret', client_secret);

                if (typeof callback === 'function') {
                    callback(self.user.data);
                }
            },
            (error) => {
                errorCallback(error)
            }
        );
    }

    loginWithTokens(access_token ,refresh_token, expires_in) {
        let now = new Date();
        let expireTime = now.setSeconds(now.getSeconds() + expires_in) / 1000;

        this.user.data.token =  access_token;
        this.user.data.refresh_token =  refresh_token;
        this.user.data.exp =  expireTime;
        this.user.data.timezone =  'UTC';

        this.user.loggedIn = true;

        Cookies.set('api_oauth_token', this.user.data.token);
        Cookies.set('api_token_expire', expireTime);
        Cookies.set('api_refresh_token', this.user.data.refresh_token);
        Cookies.set('api_client_id', this.client_id);
        Cookies.set('api_client_secret', this.client_secret);
    }

    logout(callback) {
        this.user.reset();

        Cookies.remove('api_oauth_token');
        Cookies.remove('api_token_expire');
        Cookies.remove('api_refresh_token');
        Cookies.remove('api_client_id');
        Cookies.remove('api_client_secret');

        if (typeof callback === 'function') {
            callback(this.user.data);
        }
    }

    /**
     *
     * @returns {Promise}
     */
    tryLoginFromCookies() {
        //todo: not secure
        // login from cookies if not loggedin
        if(!this.user.is('loggedIn')){
            let apiOauthToken = Cookies.get('api_oauth_token');
            let apiTokenExpire = Cookies.get('api_token_expire');
            let refreshToken = Cookies.get('api_refresh_token');
            let clientId = Cookies.get('api_client_id');
            let clientSecret = Cookies.get('api_client_secret');

            if (apiOauthToken && refreshToken && clientId && clientSecret) {
                this.user.data = {
                    exp: apiTokenExpire,
                    token: apiOauthToken,
                    refreshToken,
                    customer: null,
                    timezone: 'UTC',
                    clientId,
                    clientSecret
                };

                this.user.loggedIn = true;
            }
        }

        return new Promise( (resolve,reject) => {
            this.user.loggedIn ? resolve('logged in from cookie') : reject('couldn\'t login from cookie');
        });
    }

    getValidToken(callback, errorCallback) {
        let self = this;

        // get max token age
        let now = new Date();
        let tokenExpiration = now.setSeconds(now.getSeconds() + 5) / 1000;
        
        // Is the user already logged in and token is not (almost) expired, then directly return the user object
        if (this.user.is('loggedIn') && this.user.get('exp') >= tokenExpiration) {
            callback(this.user.get('token'));
            return;
        }
        if(this.user.is('loggedIn')){
            this.refreshToken(callback,errorCallback);
            return;
        }
        
        // Is there already a token in the url? We can directly return the token string in the callback
        if (Utils.getUrlParameter('token')) {
            callback(Utils.getQueryString('token'));
        }

        errorCallback("Not logged in");
    }

    refreshToken(callback, errorCallback){
        let self = this;

        let grant_type = 'refresh_token';
        let refresh_token = this.user.data.refresh_token;
        let client_id = this.user.data.client_id;
        let client_secret = this.user.data.client_secret;

        this.oAuthRequest(
            Request.getServer() + 'oauth/v2/token',
            {grant_type, client_id, client_secret, refresh_token},
            response => {
                let now = new Date();
                let expireTime = now.setSeconds(now.getSeconds() + 3600) / 1000;

                self.user.data = {
                    exp: expireTime,
                    token: response.access_token,
                    refresh_token: response.refresh_token,
                };

                Cookies.set('api_oauth_token', self.user.data.token);
                Cookies.set('api_refresh_token', self.user.data.refresh_token);
                Cookies.set('api_client_id', client_id);
                Cookies.set('api_client_secret', client_secret);

                if (typeof callback === 'function') {
                    callback(self.user.data.token);
                }
            },
            (error) => {
                this.user.reset();
                errorCallback(error);
            }
        );
    }

    getUrl(url) {
      let requestUrl = url;
  
      if (/^(f|ht)tps?:\/\//i.test(url) === false) {
        requestUrl = 'http://api.rideabel.local/app_dev.php/' + url;
      }
  
      return requestUrl;
    }

    oAuthRequest(url, data, successCallback, errorCallback) {
        let xhr = new XMLHttpRequest();
        xhr.open('POST', this.getUrl(url), true);
  
        xhr.responseType = "json";
    
        // Set the timeout of this call to 30 seconds
        let xhrTimeout = setTimeout(() => {
          xhr.abort();
        }, 30 * 1000);
    
        let callback = type => {
          clearTimeout(xhrTimeout);
    
          switch (type) {
            case "error":
              if (typeof errorCallback === "function") {
                let response = xhr.statusText;
                if (xhr.readyState !== 4) {
                  response = "Request is not finished";
                } else if (xhr.status !== 200) {
                  if (xhr.status === 0) {
                    response = "Request failed to complete";
                  } else {
                      response = xhr.response ?
                          xhr.response :
                          `Invalid response status: ${xhr.status}`;
                  }
                }
    
                try {
                  let errorData = xhr.response;
    
                  if (
                    typeof errorData === "string" &&
                    errorData.length > 0 &&
                    errorData[0] === "{"
                  ) {
                    // This is very likely to be a JSON string
                    errorData = JSON.parse(errorData);
                  }
    
                  if (typeof errorData.result === "object") {
                    errorCallback(
                      errorData.result.ErrorText || response,
                      errorData.result.ErrorCode || null
                    );
                  } else {
                    errorCallback(response);
                  }
                } catch (e) {
                  errorCallback(response);
                }
              }
              break;
    
            default:
              let response = xhr.response;
    
              if (typeof xhr.response !== "object") {
                response = JSON.parse(response);
              }
    
              // Success
              if (typeof window.onAbelKitPostRequest === "function") {
                window.onAbelKitPostRequest(response);
              }
    
              if (typeof successCallback === "function") {
                successCallback(response);
              }
          }
        };
    
        xhr.onload = () => {
          if (xhr.status === 200) {
            callback("success");
          } else {
            callback("error");
          }
        };
    
        xhr.ontimeout = xhr.onerror = () => {
          callback("error");
        };
    
        if (typeof xhr.onabort !== "undefined") {
          xhr.onabort = () => {
            callback("error");
          };
        }
    
        const formData = new FormData();
        Object.keys(data).forEach(key => formData.append(key, data[key]));
    
        return xhr.send(
          formData
        );
      }
}
