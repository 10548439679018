import BaseService from './BaseService';
import * as objects from '../objects/';

/**
 * AbelAdminService
 */

export default class AbelAdminService extends BaseService {
    constructor(AbelAdminKit) {
        super(AbelAdminKit, 'AbelAdminService');
    }

    /**
     * Add a new company. <br>
     * Makes a middleware POST call to "AbelAdminService/Company/".
     *
     * @param {AKACompany} AKACompany
     * @returns {Promise}
     */
    addCompany(AKACompany) {
        return this.postCall('Company/', AKACompany);
    }

    /**
     * Update an existing company by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Company/{$id}".
     *
     * @param {number} id - The id of the company
     * @param {AKACompany} AKACompany
     * @returns {Promise}
     */
    updateCompany(id, AKACompany) {
        return this.postCall(`Company/${id}`, AKACompany);
    }

    /**
     * Delete an existing company by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Company/{$id}/Remove".
     *
     * @param {number} id - The id of the company
     * @returns {Promise}
     */
    removeCompany(id) {
        return this.postCall(`Company/${id}/Remove`);
    }

    /**
     * Get the data of an existing company by its id. <br>
     * Makes a middleware GET call to "AbelAdminService/Company/{$id}".
     *
     * @param {number} id - The id of the company
     * @returns {Promise}
     */
    getCompany(id) {
        return this.call(`Company/${id}`);
    }

    /**
     * Get the list of all companies or a list of companies by search query. <br>
     * Makes a middleware GET call to "AbelAdminService/Company/Search/{$query}".
     *
     * @example
     * AbelAdminService.getCompanies({ name: 'Albert H.', vat: '543789123' });
     *
     * @param {Object|string} [params] -  Object containing search properties, or a string which searches for name, email or CompanyId
     * @param {string} [params.id] - Search query as object
     * @param {string} [params.name] - Name
     * @param {string} [params.address] - Address
     * @param {string} [params.email] - Email address
     * @param {string} [params.vat] - VAT number
     * @param {string} [params.regnr] - Company registration number
     * @param {string} [params.iban] - IBAN bank account number
     * @param {date} [params.regbefore] - Date is before registration
     * @param {date} [params.regafter] - Date is after registration
     *
     * @returns {Promise}
     */
    getCompanies(params = {}) {
        let query = this.urlQuery(params);

        return this.call(`Company/Search/${query}`);
    }

    /**
     * Add a new customer. <br>
     * Makes a middleware POST call to "AbelAdminService/Customer/".
     *
     * @param {AKACustomer} AKACustomer
     * @returns {Promise}
     */
    addCustomer(AKACustomer) {
        return this.postCall('Customer/', AKACustomer);
    }

    /**
     * Update an existing customer by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Customer/{$id}".
     *
     * @param {number} id - The id of the company
     * @param {AKACustomer} AKACustomer
     * @returns {Promise}
     */
    updateCustomer(id, AKACustomer) {
        return this.postCall(`Customer/${id}`, AKACustomer);
    }

    /**
     * Get the data of an existing customer by its id. <br>
     * Makes a middleware GET call to "AbelAdminService/Customer/{$id}".
     *
     * @param {number} id - The id of the customer
     * @returns {Promise}
     */
    getCustomer(id) {
        return this.call(`Customer/${id}`);
    }

    /**
     * Get the list of all customers or a list of customers by search query. <br>
     * Makes a middleware GET call to "AbelAdminService/Customer/Search/{$query}".
     *
     * @example
     * AbelAdminService.getCustomers({ first: 'Albert', tel: '543789123' });
     *
     * @param {Object|string} [params] - Object containing search properties, or a string which searches for name, email or CustomerId
     * @param {string} [params.id] - CustomerId
     * @param {string} [params.hash] - Hash of CustomerId
     * @param {string} [params.first] - First name
     * @param {string} [params.last] - Last name
     * @param {string} [params.email] - Email address
     * @param {string} [params.tel] - Telephone number
     * @param {string} [params.promo] - Promo code
     * @param {date} [params.before] - Date before registration date
     * @param {date} [params.after] - Date after registration date
     *
     * @returns {Promise}
     */
    getCustomers(params = {}) {
        let query = this.urlQuery(params);

        return this.call(`Customer/Search/${query}`);
    }

    /**
     * I have no idea what this does. <br>
     * Makes a middleware GET call to "Customer/Performance?grouping=${grouping}&start=${start}&end=${end}".
     *
     * @param {date} start - The begin of the date range
     * @param {date} end - The end of the date range
     * @param {('hours'|'days'|'weeks'|'months'|'years')} grouping - Group the data by this value
     * @returns {Promise}
     */
    getCustomerPerformance(start, end, grouping = 'days') {
        return this.call(`Customer/Performance?grouping=${grouping}&start=${start}&end=${end}`);
    }

    /**
     * Send account validation email to customer. <br>
     * Makes a middleware POST call to "AbelAdminService/Customer/{$id}/ValidateEmail".
     *
     * @param {number} id - The id of the customer
     * @returns {Promise}
     */
    validateCustomerEmail(id) {
        return this.postCall(`Customer/${id}/ValidateEmail`);
    }

    /**
     * Re-send account confirmation email to customer. <br>
     * Makes a middleware POST call to "AbelAdminService/Customer/{$id}/ResendConfirmationEmail".
     *
     * @param {number} id - The id of the customer
     * @returns {Promise}
     */
    resendCustomerConfirmationEmail(id) {
        return this.postCall(`Customer/${id}/ResendConfirmationEmail`);
    }

    /**
     * Add a new trip. <br>
     * Makes a middleware POST call to "AbelAdminService/Trip/".
     *
     * @param {AKATrip} AKATrip
     * @returns {Promise}
     */
    addTrip(AKATrip) {
        return this.postCall('Trip/Book', AKATrip);
    }

    /**
     * Update an existing trip by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Trip/{$id}".
     *
     * @param {number} id - The id of the company
     * @param {AKATrip} AKATrip
     * @returns {Promise}
     */
    updateTrip(id, AKATrip) {
        return this.postCall(`Trip/${id}`, AKATrip);
    }

    /**
     * Delete an existing trip by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Trip/{$id}/Remove".
     *
     * @param {number} id - The id of the company
     * @returns {Promise}
     */
    removeTrip(id) {
        return this.postCall(`Trip/${id}/Remove`);
    }

    /**
     * Get the data of an existing trip by its id. <br>
     * Makes a middleware GET call to "AbelAdminService/Trip/{$id}".
     *
     * @param {number} id - The id of the trip
     * @returns {Promise}
     */
    getTrip(id) {
        return this.call(`Trip/${id}`);
    }

    /**
     * Get the trip tariff of trip data. <br>
     * Makes a middleware GET call to "AbelAdminService/Trip/Tariff".
     *
     * @param {AKATrip} AKATrip
     * @returns {Promise}
     */
    tripTariff(AKATrip) {
        return this.call('Trip/Tariff', AKATrip);
    }

    /**
     * Get the list of all trips or a list of trips by search query. <br>
     * Makes a middleware GET call to "AbelAdminService/Trip/Search/{$query}".
     *
     * @example
     * AbelAdminService.getTrips({ customerid: '1', dest: 'Examplestreet' });
     *
     * @param {Object|string} [params] - Object containing search properties, or a string which searches for TripId
     * @param {string} [params.id] - TripId
     * @param {string} [params.invoice] - Invoice number
     * @param {string} [params.customer] - Name of customer
     * @param {string} [params.customerid] - CustomerId of customer
     * @param {string} [params.driver] - Name of driver
     * @param {string} [params.driverid] - DriverId of driver
     * @param {string} [params.vehicle] - License plate
     * @param {string} [params.orig] - Origination address
     * @param {string} [params.dest] - Destination address
     * @param {date} [params.before] - Date is before BookingTime
     * @param {date} [params.after] - Date is after BookingTime
     * @param {string} [params.status] - TripState or 'open', 'fail', 'success'
     * @param {string} [params.promo] - 'yes' or 'no'
     * @param {string} [params.pspref] - Payment service provider
     *
     * @returns {Promise}
     */
    getTrips(params = {}) {
        let query = this.urlQuery(params);

        return this.call(`Trip/Search/${query}`);
    }

    /**
     * Cancel an existing and active trip by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Trip/{$id}/Cancel".
     *
     * @param {number} id - The id of the trip
     * @param {number} customerId - The id of the customer where the trip belongs to
     * @returns {Promise}
     */
    cancelTrip(id, customerId) {
        return this.postCall(`Trip/${id}/Cancel`, { CustomerId: customerId });
    }

    /**
     * Resend the invoice of an existing and finished trip by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Trip/{$id}/ResendInvoice".
     *
     * @param {number} id - The id of the trip
     * @param {number} customerId - The id of the customer where the trip belongs to
     * @returns {Promise}
     */
    resendTripInvoice(id, customerId) {
        return this.postCall(`Trip/${id}/ResendInvoice`, { CustomerId: customerId });
    }

    /**
     * Regenerate the map of a trip by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Trip/{$id}/RegenerateMap".
     *
     * @param {number} id - The id of the trip
     * @returns {Promise}
     */
    regenerateMap(id) {
        return this.postCall(`Trip/${id}/RegenerateMap`);
    }

    /**
     * Get the list of all trip states or a list of trip states by search query. <br>
     * Makes a middleware GET call to "AbelAdminService/Tripstate/Search/{$query}".
     *
     * @example
     * AbelAdminService.getTripStates({ driver: 'Tom', customer: 'Johannes' });
     *
     * @param {Object} [params] - Object containing search properties
     * @param {string} [params.tripid] - TripId
     * @param {string} [params.customer] - Name of customer
     * @param {string} [params.customerid] - CustomerId of customer
     * @param {string} [params.driver] - Name of driver
     * @param {string} [params.driverid] - DriverId of driver
     * @param {string} [params.vehicle] - License plate
     * @param {string} [params.before] - Date before BookingTime
     * @param {string} [params.after] - Date after BookingTime
     * @param {string} [params.status] - TripState (tripAccepted, customerPickedUp, etc)
     *
     * @returns {Promise}
     */
    getTripStates(params = {}) {
        let query = this.urlQuery(params);

        return this.call(`Tripstate/Search/${query}`);
    }

    /**
     * Add a new driver. <br>
     * Makes a middleware POST call to "AbelAdminService/Driver/".
     *
     * @param {AKADriver} AKADriver
     * @returns {Promise}
     */
    addDriver(AKADriver) {
        return this.postCall('Driver/', AKADriver);
    }

    /**
     * Update an existing driver by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Driver/{$id}".
     *
     * @param {number} id - The id of the driver
     * @param {AKADriver} AKADriver
     * @returns {Promise}
     */
    updateDriver(id, AKADriver) {
        return this.postCall(`Driver/${id}`, AKADriver);
    }

    /**
     * Delete an existing driver by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Driver/{$id}/Remove".
     *
     * @param {number} id - The id of the driver
     * @returns {Promise}
     */
    removeDriver(id) {
        return this.postCall(`Driver/${id}/Remove`);
    }

    /**
     * Get the data of an existing driver by its id. <br>
     * Makes a middleware GET call to "AbelAdminService/Driver/{$id}".
     *
     * @param {number} id - The id of the driver
     * @returns {Promise}
     */
    getDriver(id) {
        return this.call(`Driver/${id}`);
    }

    /**
     * Get the list of all drivers or a list of drivers by search query. <br>
     * Makes a middleware GET call to "AbelAdminService/Driver/Search/{$query}".
     *
     * @example
     * AbelAdminService.getDrivers({ first: 'John', last: 'Goodman' });
     *
     * @param {Object|string} [params] - Object containing search properties, or a string which searches for name or email
     * @param {string} [params.id] - DriverId
     * @param {string} [params.first] - First name
     * @param {string} [params.last] - Last name
     * @param {string} [params.email] - Email address
     * @param {string} [params.tel] - Telephone number
     * @param {date} [params.regbefore] - Date before registration date
     * @param {date} [params.regafter] - Date after registration date
     *
     * @returns {Promise}
     */
    getDrivers(params = {}) {
        let query = this.urlQuery(params);

        return this.call(`Driver/Search/${query}`, null, objects.AKAGetDriversResponse);
    }

    getDriverPerformance(start, end, id = '', grouping = 'days') {
        return this.call(`Driver/Performance/${id}?grouping=${grouping}&start=${start}&end=${end}`);
    }

    getPerformancePerDriver(start, end) {
        return this.call(`Driver/PerformancePerDriver?start=${start}&end=${end}`);
    }

    resendDriverWelcomeEmail(id) {
        return this.postCall(`Driver/${id}/ResendWelcomeEmail`);
    }

    /**
     * Add a new trip. <br>
     * Makes a middleware POST call to "AbelAdminService/Trip/".
     *
     * @param {AKAVehicle} AKAVehicle
     * @returns {Promise}
     */
    addVehicle(AKAVehicle) {
        return this.postCall('Vehicle/', AKAVehicle);
    }

    /**
     * Update an existing vehicle by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Vehicle/{$id}".
     *
     * @param {number} id - The id of the company
     * @param {AKAVehicle} AKAVehicle
     * @returns {Promise}
     */
    updateVehicle(id, AKAVehicle) {
        return this.postCall(`Vehicle/${id}`, AKAVehicle);
    }

    /**
     * Delete an existing vehicle by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Vehicle/{$id}/Remove".
     *
     * @param {number} id - The id of the vehicle
     * @returns {Promise}
     */
    removeVehicle(id) {
        return this.postCall(`Vehicle/${id}/Remove`);
    }

    /**
     * Get the data of an existing vehicle by its id. <br>
     * Makes a middleware GET call to "AbelAdminService/Vehicle/{$id}".
     *
     * @param {number} id - The id of the vehicle
     * @returns {Promise}
     */
    getVehicle(id) {
        return this.call(`Vehicle/${id}`);
    }

    /**
     * Get the list of all vehicles or a list of vehicles by search query. <br>
     * Makes a middleware GET call to "AbelAdminService/Vehicle/Search/{$query}".
     *
     * @example
     * AbelAdminService.getVehicles('AA-BB-03');
     *
     * @param {string} licensePlate - The license plate of the vehicle to search for
     * @returns {Promise}
     */
    getVehicles(licensePlate = '') {
        return this.call(`Vehicle/Search/${licensePlate}`, null, objects.AKAGetVehiclesResponse);
    }

    /**
     * Add a new promo code. <br>
     * Makes a middleware POST call to "AbelAdminService/Promocode/".
     *
     * @param {AKAPromocode} AKAPRomocode
     * @returns {Promise}
     */
    addPromoCode(AKAPRomocode) {
        return this.postCall('Promocode/', AKAPRomocode);
    }

    /**
     * Update an existing promo code by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Promocode/{$id}".
     *
     * @param {number} id - The id of the promo code
     * @param {AKAPromocode} AKAPromocode
     * @returns {Promise}
     */
    updatePromoCode(id, AKAPromocode) {
        return this.postCall(`Promocode/${id}`, AKAPromocode);
    }

    /**
     * Delete an existing promo code by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Promocode/{$id}/Remove".
     *
     * @param {number} id - The id of the promo code
     * @returns {Promise}
     */
    removePromoCode(id) {
        return this.postCall(`Promocode/${id}/Remove`);
    }

    /**
     * Get the data of an existing promo code by its id. <br>
     * Makes a middleware GET call to "AbelAdminService/Promocode/{$id}".
     *
     * @param {number} id - The id of the driver
     * @returns {Promise}
     */
    getPromoCode(id) {
        return this.call(`Promocode/${id}`);
    }

    /**
     * Get the list of all promo codes or a list of promo codes by search query. <br>
     * Makes a middleware GET call to "AbelAdminService/Promocode/Search/{$query}".
     *
     * @example
     * AbelAdminService.getPromoCodes({ segment: 'Zendesk });
     *
     * @param {Object|string} [params] - Object containing search properties, or a string which searches for name
     * @param {string} [params.id] - DiscountId
     * @param {string} [params.segment] - Segment
     *
     * @returns {Promise}
     */
    getPromoCodes(params = {}) {
        let query = this.urlQuery(params);

        return this.call(`Promocode/Search/${query}`);
    }

    /**
     * Get the data of an existing payment method by its id. <br>
     * Makes a middleware GET call to "AbelAdminService/Paymentmethod/id: {$id}".
     *
     * @param {number} id - The id of the payment method
     * @returns {Promise}
     */
    getPaymentMethod(id) {
        return this.call(`Paymentmethod/Search/id: ${id}`);
    }

    /**
     * Get the list of all payment methods or a list of payment methods by search query. <br>
     * Makes a middleware GET call to "AbelAdminService/Paymentmethod/Search/{$query}".
     *
     * @example
     * AbelAdminService.getPaymentMethods({ identifier: '1234', method: 'adyen_creditcard' });
     *
     * @param {Object} [params] - Object containing search properties
     * @param {string} [params.id] - id
     * @param {string} [params.hashid] - hashid
     * @param {string} [params.identifier] - Account identifier (e.g. last 4 digits)
     * @param {string} [params.alias] - Account alias (unique identifier)
     * @param {string} [params.customerid] - CustomerId of customer
     * @param {string} [params.method] - Payment method (adyen_creditcard, adyen_sepa_dd_ideal, braintree_paypal, mangopay_creditcard)
     * @param {string} [params.status] - Payment status (Authorised, Failed, Refused, AwaitingAuthorisation)
     * @param {string} [params.type] - Type (e.g. MASTERCARD, VISA, ABNANL2A)
     * @param {string} [params.active] - Card is active (1) or inactive (0)
     * @param {string} [params.default] - Card is default (1) or not default (0)
     *
     * @returns {Promise}
     */
    getPaymentMethods(params = {}) {
        const query = this.urlQuery(params);
        const structure = [objects.AKAPaymentMethods];
        return this.call(`Paymentmethod/Search/${query}`, null, structure);
    }

    /**
     * Add a new payment method to a customer.
     * Makes a middleware POST call to "AbelAdminService/Customer/PaymentMethod".
     *
     * @param {integer} customerId - ID for the customer to add the payment method to
     * @param {Object} data        - Object containing payment method data
     *
     * @returns {Promise}
     */
    addCustomerPaymentMethod(customerId, data) {
        return this.postCall(`Customer/${customerId}/PaymentMethod`, data);
    }

    /**
     * Delete an existing discount by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Discount/{$id}/Remove".
     *
     * @param {number} id - The id of the discount
     * @returns {Promise}
     */
    removeDiscount(id) {
        return this.postCall(`Discount/${id}/Remove`);
    }

    /**
     * Get the list of all discounts or a list of discounts by search query. <br>
     * Makes a middleware GET call to "AbelAdminService/Discount/Search/{$query}".
     *
     * @example
     * AbelAdminService.getDiscounts({ customer: 'Tim Botron', status: 'used' });
     *
     * @param {Object|string} [params] - Object containing search properties, or a string which searches for name
     * @param {string} [params.id] - DiscountId
     * @param {string} [params.type] - 'marketing' or 'personal'
     * @param {string} [params.customer] - Name of customer
     * @param {string} [params.customerid] - CustomerId of customer
     * @param {string} [params.relation] - Name of the person from who the customer got his promocode
     * @param {string} [params.relationid] - CustomerId of the person from who the customer got his promocode is. Only for personal promo codes
     * @param {string} [params.status] - 'usable', 'used', 'expired', 'notyetusable' or 'open' ('usable' OR 'notyetusable')
     * @param {string} [params.tripid] - TripId of trip
     * @param {date} [params.before] - Date before created
     * @param {date} [params.after] - Date after created
     *
     * @returns {Promise}
     */
    getDiscounts(params = {}) {
        let query = this.urlQuery(params);

        return this.call(`Discount/Search/${query}`);
    }

    /**
     * Adds multiple Discounts to Customers
     * Makes a middleware POST call to "AbelAdminService/Discount/{$query}."
     * 
     * @param {Object|list|object|string} [Discounts] An object containing a list of Discount objects
     * @param {string} [Discount.Promocode] - The promocode to add to the selected customer
     * @param {string} [Discount.CustomerId] - CustomerId of the person to add the selected promocode to
     * 
     * @returns {Promise}
     */
    addDiscount(Discounts) {
        return this.postCall('Discount/', Discounts);
    }

    /**
     * Get the data of an existing logged activity by its id. <br>
     * Makes a middleware GET call to "AbelAdminService/Activity/{$id}".
     *
     * @param {number} id - The id of the activity
     * @returns {Promise}
     */
    getActivity(id) {
        return this.call(`Activity/${id}`);
    }

    /**
     * Get the list of all logged activities or a list of activities by search query. <br>
     * Makes a middleware GET call to "AbelAdminService/Activity/Search/{$query}".
     *
     * @example
     * AbelAdminService.getActivities({ customer: 'Tim Botron', status: 'used' });
     *
     * @param {Object} [params] - Object containing search properties
     * @param {string} [params.customerid] - CustomerId of customer
     * @param {string} [params.driverid] - DriverId of driver
     * @param {string} [params.event] - Event
     * @param {string} [params.level] - Event level (info, warning or error)
     * @param {string} [params.str1] - Event details string 1
     * @param {string} [params.str2] - Event details string 2
     * @param {string} [params.str3] - Event details string 3
     * @param {string} [params.int1] - Event details integer 1
     * @param {string} [params.int2] - Event details integer 2
     * @param {string} [params.float1] - Event details float 1
     * @param {string} [params.float2] - Event details float 2
     * @param {string} [params.source] - Event source
     * @param {string} [params.sourcetype] - Event source type
     * @param {string} [params.sourceversion] - Event source version
     * @param {date} [params.before] - Date before log time
     * @param {date} [params.after] - Date after log time
     *
     * @returns {Promise}
     */
    getActivities(params = {}) {
        let query = this.urlQuery(params);

        return this.call(`Activity/Search/${query}`);
    }

    /**
     * I am not sure yet. <br>
     * Makes a middleware GET call to "AbelAdminService/Map/FastForward/${date}".
     *
     * @param {date} date - Formatted date string
     * @returns {Promise}
     */
    fastForwardMap(date) {
        return this.call(`Map/FastForward/${date}`);
    }

    /**
     * I am not sure yet. <br>
     * Makes a middleware GET call to "AbelAdminService/Map/FastBackward/${date}".
     *
     * @param {date} date - Formatted date string
     * @returns {Promise}
     */
    fastBackwardMap(date) {
        return this.call(`Map/FastBackward/${date}`);
    }

    /**
     * I am not sure yet. <br>
     * Makes a middleware GET call to "AbelAdminService/Map/State/${date}".
     *
     * @param {date} date - Formatted date string
     * @returns {Promise}
     */
    getMapState(date) {
        return this.call(`Map/State/${date}`);
    }

    /**
     * Get the data of an existing debug log entry by its id. <br>
     * Makes a middleware GET call to "AbelAdminService/Debuglog/{$id}".
     *
     * @param {number} id - The id of the debug log entry
     * @returns {Promise}
     */
    getDebugLog(id) {
        return this.call(`Debuglog/${id}`);
    }

    /**
     * Get the list of all debug log entries or a list of debug logs by search query. <br>
     * Makes a middleware GET call to "AbelAdminService/Debuglog/Search/{$query}".
     *
     * @example
     * AbelAdminService.getDebugLogs({ driverid: '2413', parameters: 'valid' });
     *
     * @param {Object} [params] - Object containing search properties
     * @param {string} [params.customerid] - CustomerId of customer
     * @param {string} [params.driverid] - DriverId of driver
     * @param {string} [params.adminid] - AdminId of admin
     * @param {string} [params.service] - Service
     * @param {string} [params.apiversion] - Api-Version header
     * @param {string} [params.parameters] - 'valid' or 'invalid'
     * @param {string} [params.response] - 'valid' or 'invalid'
     * @param {date} [params.before] - Date is before log time
     * @param {date} [params.after] - Date is after log time
     *
     * @returns {Promise}
     */
    getDebugLogs(params = {}) {
        let query = this.urlQuery(params);

        return this.call(`Debuglog/Search/${query}`);
    }

    /**
     * Add a new user. <br>
     * Makes a middleware POST call to "AbelAdminService/User/".
     *
     * @param {AKAUser} AKAUser
     * @returns {Promise}
     */
    addUser(AKAUser) {
        return this.postCall('User/', AKAUser);
    }

    /**
     * Update an existing user by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/User/{$id}".
     *
     * @param {number} id - The id of the promo code
     * @param {AKAUser} AKAUser
     * @returns {Promise}
     */
    updateUser(id, AKAUser) {
        return this.postCall(`User/${id}`, AKAUser);
    }

    /**
     * Delete an existing user by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/User/{$id}/Remove".
     *
     * @param {number} id - The id of the user
     * @returns {Promise}
     */
    removeUser(id) {
        return this.postCall(`User/${id}/Remove`);
    }

    /**
     * Get the data of an existing user by its id. <br>
     * Makes a middleware GET call to "AbelAdminService/User/{$id}".
     *
     * @param {number} id - The id of the user
     * @returns {Promise}
     */
    getUser(id) {
        return this.call(`User/${id}`);
    }

    /**
     * Get the list of all users or a list of users by search query. <br>
     * Makes a middleware GET call to "AbelAdminService/Activity/Search/{$query}".
     *
     * @example
     * AbelAdminService.getUsers( ? );
     *
     * @param {Object} params - Search query as object
     * @returns {Promise}
     */
    getUsers(params = {}) {
        let query = this.urlQuery(params);

        return this.call(`User/Search/${query}`);
    }

    /**
     * Add a new meeting point. <br>
     * Makes a middleware POST call to "AbelAdminService/Meetingpoint/".
     *
     * @param {AKAMeetingPointArea} meetingPointArea
     * @returns {Promise}
     */
    addMeetingPoint(meetingPointArea) {
        return this.postCall('Meetingpoints/', meetingPointArea);
    }

    /**
     * Update an existing meeting point by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Meetingpoint/{$id}".
     *
     * @param {AKAMeetingPointArea} meetingPointArea
     * @returns {Promise}
     */
    updateMeetingPoint(meetingPointArea) {
        return this.postCall(`Meetingpoints/${meetingPointArea.id}`, meetingPointArea);
    }

    /**
     * Delete an existing meeting point by its id. <br>
     * Makes a middleware POST call to "AbelAdminService/Meetingpoint/{$id}/Remove".
     *
     * @param {number} id - The id of the meeting point
     * @returns {Promise}
     */
    removeMeetingPoint(id) {
        return this.postCall(`Meetingpoints/${id}/Remove`);
    }

    /**
     * Get the meeting points data of an existing meeting point area, by its id. <br>
     * Makes a middleware GET call to "AbelAdminService/Meetingpoints/{$id}".
     *
     * @param {number} id - The id of the user
     * @returns {Promise}
     */
    getMeetingPoint(id) {
        return this.call(`Meetingpoints/${id}`, null, objects.AKAMeetingPoints);
    }

    /**
     * Get all meeting point areas. <br>
     * Makes a middleware GET call to "AbelAdminService/Meetingpoints/".
     *
     * @returns {Promise}
     */
    getMeetingPoints() {
        return this.call('Meetingpoints/', null, objects.AKAMeetingPointAreas);
    }

    /**
     * Search through all meeting point areas by a single search string. <br>
     * Makes a middleware GET call to "AbelAdminService/Meetingpoints/Search/{$searchTerm}".
     *
     * @returns {Promise}
     */
    searchMeetingPoints(searchTerm) {
        return this.call(`Meetingpoints/Search/${searchTerm}`);
    }

    /**
     * Get the Profile settings as object. <br>
     * Makes a middleware GET call to "AbelAdminService/Profile".
     *
     * @returns {Promise}
     */
    getProfileSettings() {
        return this.call('Profile');
    }

    /**
     * Set the Profile settings. <br>
     * Makes a middleware POST call to "AbelAdminService/Profile".
     *
     * @param {AKASettingsProfile} AKASettingsProfile
     * @returns {Promise}
     */
    updateProfileSettings(AKASettingsProfile) {
        return this.call('Profile', AKASettingsProfile);
    }

    /**
     * Get the Apps settings as object. <br>
     * Makes a middleware GET call to "AbelAdminService/Apps".
     *
     * @returns {Promise}
     */
    getAppsSettings() {
        return this.call('Apps');
    }

    /**
     * Set the Apps settings. <br>
     * Makes a middleware POST call to "AbelAdminService/Apps".
     *
     * @param {AKASettingsApps} AKASettingsApps
     * @returns {Promise}
     */
    updateAppsSettings(AKASettingsApps) {
        return this.call('Apps', AKASettingsApps);
    }

    /**
     * Get the Bi (database) settings as object. <br>
     * Makes a middleware GET call to "AbelAdminService/Bi".
     *
     * @returns {Promise}
     */
    getBiSettings() {
        return this.call('Bi');
    }

    /**
     * Set the Apps settings. <br>
     * Makes a middleware POST call to "AbelAdminService/Bi".
     *
     * @param {AKASettingsBi} AKASettingsBi
     * @returns {Promise}
     */
    updateBiSettings(AKASettingsBi) {
        return this.call('Bi', AKASettingsBi);
    }

    /**
     * Get the Email settings as object. <br>
     * Makes a middleware GET call to "AbelAdminService/Mail".
     *
     * @returns {Promise}
     */
    getEmailSettings() {
        return this.call('Mail');
    }

    /**
     * Set the Email settings. <br>
     * Makes a middleware POST call to "AbelAdminService/Mail".
     *
     * @param {AKASettingsEmail} AKASettingsEmail
     * @returns {Promise}
     */
    updateEmailSettings(AKASettingsEmail) {
        return this.call('Mail', AKASettingsEmail);
    }

    /**
     * Get the Payments settings as object. <br>
     * Makes a middleware GET call to "AbelAdminService/Payments".
     *
     * @returns {Promise}
     */
    getPaymentsSettings() {
        return this.call('Payments');
    }

    /**
     * Set the Payments settings. <br>
     * Makes a middleware POST call to "AbelAdminService/Payments".
     *
     * @param {AKASettingsPayments} AKASettingsPayments
     * @returns {Promise}
     */
    updatePaymentsSettings(AKASettingsPayments) {
        return this.call('Payments', AKASettingsPayments);
    }

    /**
     * Get the Templates settings as object. <br>
     * Makes a middleware GET call to "AbelAdminService/Templates".
     *
     * @returns {Promise}
     */
    getTemplatesSettings() {
        return this.call('Templates');
    }

    /**
     * Set the Templates settings. <br>
     * Makes a middleware POST call to "AbelAdminService/Templates".
     *
     * @param {AKASettingsTemplates} AKASettingsTemplates
     * @returns {Promise}
     */
    updateTemplatesSettings(AKASettingsTemplates) {
        return this.call('Templates', AKASettingsTemplates);
    }

    sendTemplateSettingsEmail(template, content, subject) {
        return this.call(`Templates/${template}/SendTest`, { content: content, subject: subject });
    }

    /**
     * Make a MSSQL query directly to the backend. <br>
     * Makes a middleware POST call to "AbelAdminService/Query/Backend".
     *
     * @param {string} query - The MSSQL query
     * @returns {Promise}
     */
    getQuery(query) {
        return this.postCall('Query/Backend', query);
    }

    /**
     * Get shifts for a specific time period. <br>
     * Makes a middleware GET call to "AbelAdminService/Shift".
     *
     * @param {date} fromDate
     * @param {date} untilDate
     * @returns {Promise}
     */
    getShifts(fromDate, untilDate) {
        let query = this.queryString({ From: fromDate.toISOString(), Til: untilDate.toISOString() });

        return this.call(`Shift?${query}`, null, objects.AKAGetShiftsResponse);
    }

    /**
     * Add shifts. <br>
     * Makes a middleware POST call to "AbelAdminService/Shift".
     *
     * @param {AKAAddShifts} shift
     * @returns {Promise}
     */
    addShifts(shifts) {
        return this.postCall('Shift', shifts, objects.AKAAddShiftResponse);
    }

    /**
     * Remove shift. <br>
     * Makes a middleware POST call to "AbelAdminService/Shift".
     *
     * @param {AKShift} shift
     * @returns {Promise}
     */
    removeShift(shift) {
        return this.postCall(`Shift/${shift.shiftId}/remove`);
    }

    /**
     * Log on driver. <br>
     * Makes a middleware POST call to "AbelAdminService/Driver/{$driverId}/Logon".
     *
     * @param {AKADriverLogOn} shift
     * @returns {Promise}
     */
    driverLogOn(shift) {
        return this.postCall(`Driver/${shift.driverId}/Logon`, shift);
    }


    /**
     * Log off driver. <br>
     * Makes a middleware POST call to "AbelAdminService/Driver/{$driverId}/Logoff".
     *
     * @param {number} driverId
     * @returns {Promise}
     */
    driverLogOff(driverId) {
        return this.postCall(`Driver/${driverId}/Logoff`);
    }

    /**
     * Get trip forecasts for a specific time period. <br>
     * Makes a middleware GET call to "AbelAdminService/TripForecast".
     *
     * @param {date} fromDate
     * @param {date} untilDate
     * @returns {Promise}
     */
    getTripForecast(fromDate, untilDate) {
        let query = this.queryString({ StartTime: fromDate.toISOString(), EndTime: untilDate.toISOString() });

        return this.call(`TripForecast?${query}`, null, objects.AKAGetTripForecastResponse);
    }

    /**
     * Get possible time windows for a next booking
     */
    availableTimeWindows(data) {
        return this.call('PossibleTimeWindows', data);
    }

    /**
     * Add customer quick without email
     */
    addCustomerQuick(data) {
        return this.callNoAuth('AddCustomerQuick', data);
    }
}
