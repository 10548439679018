import BaseObject from '../BaseObject';
import AKTimeWindow from './AKTimeWindow';

export default class AKTimeWindows extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'pickupTimeWindow'      : ['PickupTimeWindow', AKTimeWindow],
            'deliveryTimeWindow'    : ['DeliveryTimeWindow', AKTimeWindow, false]
        };
    }
}
