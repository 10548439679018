import BaseObject from '../BaseObject';
import AKLocationBase from '../Location/AKLocationBase';

export default class AKDriver extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'id'            : ['DriverId', 'integer'],
            'hashId'        : ['DriverHashId', 'string'],
            'title'         : ['Title', 'string', false],
            'firstName'     : ['FirstName', 'string'],
            'lastName'      : ['LastName', 'string'],
            'telephone'     : ['Tel', 'string'],
            'email'         : ['Email', 'string'],
            'location'      : ['LocationHome', AKLocationBase, false],
            'driverCardId'  : ['DriverCardId', 'integer'],
            'companyId'     : ['CompanyId', 'integer']
        };
    }

    get name() {
        return this.firstName + ' ' + this.lastName;
    }
}
