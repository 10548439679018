import BaseObject from '../BaseObject';
import AKVehicle from '../Shift/AKVehicle';

/**
 * @param {date} from
 * @param {date} til
 */
export default class AKAGetVehiclesResponse extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'vehicles'      : ['Vehicles', [AKVehicle]]
        };
    }
}
