import BaseObject from '../BaseObject';

export default class AKAMeetingPointMetadata extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        let metadata_keys = window._user
            .get('contracts')
            .getSettingForSelectedContract('meetingPointMeta');

        // make sure the metadata is an array
        metadata_keys = metadata_keys.constructor === Array ? metadata_keys : [];

        var mapObject = {};

        metadata_keys.forEach(function(element) {
            mapObject[element] = [element, 'string', false];
        });

        return mapObject;
    }
}
