import BaseService from './BaseService';

export default class AbelService extends BaseService {
    constructor(AbelAdminKit) {
        super(AbelAdminKit,'AbelService');
        this.AbelAdminKit = AbelAdminKit;
    }

    addCustomer(data) {
        return this.callNoAuth('AddCustomer', data);
    }

    sendPasswordResetToken(emailAddress, accountType) {
        return this.callNoAuth('SendPasswordResetToken', { 'Email': emailAddress, 'AccountType': accountType });
    }

    resetPassword(data) {
        return this.callNoAuth('ResetPassword', data);
    }

    getDriverRoutes(x, y) {
        return this.callNoAuth('GetDriverRoutes', { 'X': x, 'Y': y });
    }

    tripTariff(trip, flexibilities, contractId) {
        return this.callNoAuth('TripTariff', { 'Trip': trip, 'Flexibilities': flexibilities, 'ContractId': contractId });
    }

    getLocalServiceOptions(location, language, contractId) {
        return this.callNoAuth('GetLocalServiceOptions', { 'Location': location, 'Language': language, 'ContractId': contractId });
    }

    getMeetingPoints(location, language, contractId) {
        return this.callNoAuth('GetMeetingPoints', { 'Location': location, 'Language': language, 'ContractId': contractId });
    }

    getPlaces(type, language=null) {
        //todo: in middleware POST call doesn't work
        const languageParameter = language == null ? "" : `&language=${language}`;
        return this.callNoAuth(`GetPlaces?type=${type}${languageParameter}`);
    }

    /**
     * Get a list of all OVFlex concessions.
     */
    getConcessions() {
        return this.callNoAuth('GetConcessions');
    }

    /**
     * Log a single event to the backend.
     * @param {string}      event  Identifier for the event, eg. `userLoggedIn`.
     * @param {string}      source Origin of the event, eg. `booking_website`.
     * @param {string}      sourceVersion version of the source, eg. `1.2.3`.
     * @param {string}      userType Type of user, eg. `customer`, `driver` or `admin`.
     * @param {string|null} level  Event logging level, either `error`, `warning`,
     *                             or `info`. Defaults to `info`.
     * @param {string|null} info   Optional additional information string.
     */
    addEvent(event, source, sourceVersion, userType, level, info) {
        level = level || 'info';

        const eventData = {
            'Event': event,
            'Source': source,
            'SourceVersion': sourceVersion,
            'UserType': userType,
            'Level': level,
        };

        if (info) {
            eventData.EventDataString1 = info;
        }

        return this.addEvents([eventData]);
    }

    /**
     * Log multiple events to the backend.
     * @param {Array} events 
     */
    addEvents(events) {
        return this.callNoAuth('AddEvent', { 'Events': events });
    }

    /**
     * Add customer quick without email
     * @param data
     */
    addCustomerQuick(data) {
        return this.callNoAuth('AddCustomerQuick', data);
    }
}
