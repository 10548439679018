import AbelKitError from '../components/AbelKitError';

export default class BaseObject {
    /**
     * Parses a plain js object into a class
     *
     * @param {Object} data - The data to parse
     * @param {boolean} hasServerSpecification - Data is structured as is received from the server (ao. in UpperCamelCase)
     * @returns {Object}
     * @throws {AbelKitError} throw error when foo.
     */
    constructor(data = {}, hasServerSpecification = false) {
        if (this.mapEnumData() != null) {
            //If data is already an instance of the class itself, take over the value
            if (typeof data == 'object' && data.constructor.name == this.constructor.name) {
                this.value = data.value;
                return;
            }

            //Otherwise, data should be a string that is part of the mapEnumData array
            const options = this.mapEnumData();

            if (hasServerSpecification) {
                for (const option in options) {
                    if (options[option] == data) {
                        this.value = option;
                        return;
                    }
                }
                this.raise(`Value ${data} is not in enum`);
            } else {
                if (typeof (options[data]) == 'undefined') {
                    this.raise(`Value ${data} is not in enum`);
                }
                this.value = data;
            }
        } else {
            //Loop through mapData() and parse all elements against the provided data
            let structure = this.mapData();
            if (typeof structure != 'object') this.raise('mapData is not an object');

            for (const parameterName in structure) {
                if (!Array.isArray(structure[parameterName])) this.raise('mapData element for '+parameterName+' is not an array');

                //Define what the element should be
                let parameterOptional = false;
                if (structure[parameterName].length < 2) {
                    this.raise('mapData element for '+parameterName+' has less than 2 elements');
                } else if (structure[parameterName].length >= 3) {
                    parameterOptional = (structure[parameterName][2] == false);
                }

                const parameterType = structure[parameterName][1];

                let dataParameterName = parameterName;
                if (hasServerSpecification) dataParameterName = structure[parameterName][0];

                //Check what the element is
                if (typeof (data[dataParameterName]) == 'undefined') {
                    if (!parameterOptional) this.raise('parameter '+dataParameterName+' is missing (' + (hasServerSpecification ? 'server side parameter name' : '')+ ')' );
                } else {
                    try {
                        this[parameterName] = this.parseValueAsParameter(data[dataParameterName], parameterType, hasServerSpecification);
                    } catch (e) {
                        if (e instanceof AbelKitError) {
                            this.raise(`Parameter "${dataParameterName}": ${e.message.message}.`);
                        } else {
                            this.raise(`Parameter "${dataParameterName}": ${e}.`);
                        }
                    }
                }
            }

        }
    }

    parseValueAsParameter(value, structure, hasServerSpecification) {
        const valueType = typeof value;
        const structureType = typeof structure;

        if (structure instanceof Array){
            if (!(value instanceof Array)) throw 'is not an array';
            let array = [];
            for (const element of value) {
                array.push(this.parseValueAsParameter(element, structure[0], hasServerSpecification));
            }
            return array;
        } else if (structureType == 'function') {
            return new structure(value, hasServerSpecification);
        } else if (structure == 'float') {
            return value;
        } else if (structure == 'integer') {
            if (valueType != 'number' || parseInt(value) !== value) throw 'is not an int';
            return value;
        } else if (structure == 'string') {
            if (valueType !== 'string') throw 'is not a string';
            return value;
        } else if (structure == 'date') {
            let dateValue = new Date(value);
            if (isNaN(dateValue)) throw 'is not a valid date';
            return dateValue;
        }else if (structure == 'json_object') {
            return value;
        }

        throw `structure "${structure}" cannot be interpreted.`;
    }

    /**
     * Returns a plain JS object transformation of the class to send to the server
     *
     * @returns {Object}
     */
    transformToServerSpecification() {
        if (this.mapEnumData() != null) {
            return this.value;
        } else {
            let structure = this.mapData();
            let obj = {};
            for (const parameterName in structure) {
                const serverParameterName = structure[parameterName][0];
                obj[serverParameterName] = this.transformParameterToServerSpecification(this[parameterName]);
            }
            return obj;
        }
    }

    transformParameterToServerSpecification(value) {
        const valueType = typeof value;
        if (value instanceof Array){
            let array = [];
            for (const element of value) {
                array.push(this.transformParameterToServerSpecification(element));
            }
            return array;
        } else if (value instanceof Date){
            return value.toISOString();
        } else if (valueType == 'object') {

            if (!(value instanceof BaseObject)) {
                console.log('Object not being subclass of BaseObject:');
                console.log(value);
                throw 'can only parse subclasses of BaseObject';
            }
            return value.transformToServerSpecification();
        } else {
            return value;
        }
    }

    equal(otherObject) {
        if (this.mapEnumData() != null || otherObject.mapEnumData() != null) {
            return (this.value == otherObject.value);
        } else {
            let structure = this.mapData();

            for (const parameterName in structure) {
                let parameterOptional = false;
                if (structure[parameterName].length >= 3) parameterOptional = (structure[parameterName][2] == false);

                if (!(parameterOptional && typeof this[parameterName] == 'undefined' && typeof otherObject[parameterName] == 'undefined')) {
                    if (!this.parameterEqual(this[parameterName], otherObject[parameterName])) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    parameterEqual(value, otherValue) {
        const valueType = typeof value;

        if (typeof value != typeof otherValue) return false;

        if (value instanceof Array){
            if (value === otherValue) return true;
            if (!(otherValue instanceof Array)) return false;
            if (value.length != otherValue.length) return false;

            //TODO: we now assume that the order of both arrays is equal. If it isn't, this method will return false!
            for (var i = 0; i < value.length; i++) {
                if (!this.parameterEqual(value[i], otherValue[i])) return false;
            }
            return true;
        } else if (value instanceof Date){
            return (value.toISOString() == otherValue.toISOString());
        } else if (valueType == 'object') {
            if (!(value instanceof BaseObject) || !(otherValue instanceof BaseObject)) throw 'can only parse subclasses of BaseObject';
            return value.equal(otherValue);
        } else {
            return (value == otherValue);
        }
    }

    raise(message) {
        throw new AbelKitError(`${this.constructor.name}: ${message}`);
    }

    // mapData placeholder
    mapData() {
        return [];
    }

    // mapEnumData placeholder
    mapEnumData() {
        return null;
    }
}
