import BaseObject from '../BaseObject';
import AKATripFlexibility from './AKATripFlexibility';
import AKATripDetails from './AKATripDetails';

/**
 * @param {AKATripFlexibility} flexibility
 * @param {number} paymentMethodId
 * @param {AKATripDetails} details
 *
 * @example
 * {
 *      flexibility: new AKATripFlexibility({
 *          maximumDetourIntervalMinutes: 30,
 *          notificationIntervalMinutes: 1,
 *          timeWindows: new AKTimeWindows({
 *              deliveryTimeWindow: new AKTimeWindow({
 *                  earliestTime: '2017-04-13T12:13:07.000',
 *                  latestTime: '2017-04-13T12:43:07.000'
 *              }),
 *              pickupTimeWindow: new AKTimeWindow({
 *                  earliestTime: '2017-04-13T12:07:22.000',
 *                  latestTime: '2017-04-13T12:37:22.000'
 *              })
 *          })
 *      }),
 *      paymentMethodId: 860,
 *      details: new AKATripDetails({
 *          customerId: 7,
 *          attributes: new AKATripAttributes({
 *              passengers: 2,
 *              wheelchairs: 0
 *          }),
 *          locationFrom: new AKLocationBase({
 *              geoType: new AKGeoType('wgs84'),
 *              locationType: new AKLocationType('gpscoord'),
 *              coordinate: new AKXYCoord({
 *                  x: 4.883852299999944,
 *                  y: 52.3626904
 *              }),
 *              place: 'Amsterdam',
 *              street: 'Weteringschans',
 *              houseNumber: '1',
 *              zip: '1017 RV',
 *              countryCode: 'Netherlands'
 *          }),
 *          locationTo: new AKLocationBase({
 *              geoType: new AKGeoType('wgs84'),
 *              locationType: new AKLocationType('gpscoord'),
 *              coordinate: new AKXYCoord({
 *                  x: 4.911612600000012,
 *                  y: 52.37661600000001
 *              }),
 *              place: 'Amsterdam',
 *              street: 'Dijksgracht',
 *              houseNumber: '25e',
 *              zip: '1019 BS',
 *              countryCode: 'Netherlands'
 *          })
 *      })
 * }
 */
export default class AKATrip extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'flexibility'       : ['Flexibility', AKATripFlexibility],
            'paymentMethodId'   : ['PaymentMethodId', 'integer'],
            'details'           : ['Trip', AKATripDetails]
        };
    }
}
