import BaseObject from '../BaseObject';

/**
 * @param {float} x
 * @param {float} y
 */
export default class AKXYCoord extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'x' : ['X', 'float'],
            'y' : ['Y', 'float']
        };
    }

    get latLng() {
        return {lat: this.y, lng: this.x};
    }
}
