import BaseObject from '../BaseObject';
import AKAPaymentMethods from './AKAPaymentMethods';

/**
 * @param {string} adyenMerchantAccount
 * @param {string} adyenReportUsrpwd
 * @param {string} adyenSkinCode
 * @param {string} adyenSkinHmac
 * @param {string} adyenUsrpwd
 * @param {AKAPaymentMethods} availablePaymentMethods
 * @param {boolean} blockAndUnblockCustomersBasedOnInvoiceStatus
 * @param {string} braintreeMerchantId
 * @param {string} braintreePrivateKey
 * @param {string} braintreePublicKey
 * @param {string} sumUpClientId
 * @param {string} sumUpClientSecret
 * @param {boolean} defaultPOSPaymentmethod
 * @param {boolean} defaultCashPaymentmethod
 * @param {boolean} invoiceFirstReminderInterval
 * @param {boolean} invoiceSecondReminderInterval
 * @param {float} personalPromocodeAmount
 * @param {number} vatPercentage
 */
export default class AKASettingsPayments extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'adyenMerchantAccount'                          : ['adyenMerchantAccount', 'string'],
            'adyenReportUsrpwd'                             : ['adyenReportUsrpwd', 'string'],
            'adyenSkinCode'                                 : ['adyenSkinCode', 'string'],
            'adyenSkinHmac'                                 : ['adyenSkinHmac', 'string'],
            'adyenUsrpwd'                                   : ['adyenUsrpwd', 'string'],
            'availablePaymentMethods'                       : ['availablePaymentMethods', AKAPaymentMethods],
            'blockAndUnblockCustomersBasedOnInvoiceStatus'  : ['blockAndUnblockCustomersBasedOnInvoiceStatus', 'boolean'],
            'braintreeMerchantId'                           : ['braintreeMerchantId', 'string'],
            'braintreePrivateKey'                           : ['braintreePrivateKey', 'string'],
            'braintreePublicKey'                            : ['braintreePublicKey', 'string'],
            'sumUpClientId'                                 : ['sumUpClientId', 'string'],
            'sumUpClientSecret'                             : ['sumUpClientSecret', 'string'],
            'defaultPOSPaymentmethod'                       : ['defaultPOSPaymentmethod', 'boolean'],
            'defaultCashPaymentmethod'                      : ['defaultCashPaymentmethod', 'boolean'],
            'invoiceFirstReminderInterval'                  : ['braintreeMerchantId', 'boolean'],
            'invoiceSecondReminderInterval'                 : ['invoiceSecondReminderInterval', 'boolean'],
            'personalPromocodeAmount'                       : ['personalPromocodeAmount', 'float'],
            'vatPercentage'                                 : ['vatPercentage', 'integer']
        };
    }
}
