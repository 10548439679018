import BaseObject from '../BaseObject';

/**
 * @param {number} passengers
 * @param {number} [wheelchairs]
 * @param {number} [strollers]
 * @param {number} [walkers]
 */
export default class AKATripAttributes extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'passengers'    : ['Passengers', 'integer'],
            'wheelchairs'   : ['Wheelchairs', 'integer', false],
            'strollers'     : ['Strollers', 'integer', false],
            'walkers'       : ['Walkers', 'integer', false],
            'smallPackages' : ['SmallPackages', 'integer', false],
            'mediumPackages': ['MediumPackages', 'integer', false],
            'largePackages' : ['LargePackages', 'integer', false]
        };
    }
}
