import BaseObject from '../BaseObject';

/**
 * @param {string} vehicleType
 * @param {string} licensePlate
 */
export default class AKAVehicle extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'vehicleType'       : ['VehicleType', 'string'],
            'licensePlate'      : ['LicencePlate', 'string']
        };
    }
}
