import BaseObject from '../BaseObject';
import AKAMeetingPointArea from './AKAMeetingPointArea';

export default class AKAMeetingPointAreas extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
        if (typeof this.polygons == 'undefined') {
            this.polygons = [];
        }
    }

    mapData() {
        return {
            'polygons'       : ['Polygons', [AKAMeetingPointArea], false]
        };
    }
}
