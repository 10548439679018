import BaseObject from '../BaseObject';

/**
 * @param {string} query
 */
export default class AKAQuery extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'query'   : ['Query', 'string']
        };
    }
}
