import BaseObject from '../BaseObject';
import AKAAddShift from './AKAAddShift';

/**
 * @param {date} from
 * @param {date} til
 */
export default class AKAAddShifts extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'shifts'      : ['Shifts', [AKAAddShift]]
        };
    }
}
