import Auth from './lib/components/auth';
import AuthAdmin from './lib/components/authAdmin';
import Request from './lib/components/request';
import Utils from './lib/components/utils';
import User from './lib/models/user';
import AbelService from './lib/services/AbelService';
import AbelSecureService from './lib/services/AbelSecureService';
import AbelAdminService from './lib/services/AbelAdminService';
import AbelKitError from './lib/components/AbelKitError';
import * as Objects from './lib/objects/index';

/**
 * Export all API objects individually.
 */
export {Objects};

/**
 * Main AbelKitWebAdmin public API class.*
 * @version 0.0.1 beta alpha delta
 */
export default class AbelKitWebAdmin {
    /**
     * Class constructor.
     */
    constructor(config = {}) {
        /** @type {number} - The API version of the kit. */
        this.apiVersion = 20171101;
        this.config = config;
        // configure requests
        Request.apiBaseUrl = this.config.apiBaseUrl;
        // First activate polyfills for older browsers
        Utils.polyFills();


        this._user = new User;

        // Bind all global kit events
        this._bindEvents();

        // Public event handler placeholders
        this._errorHandler = this._loginHandler = this._logoutHandler = this._requestFinishedHandler =
            this._requestErrorHandler = () => {
            };
    }

    /**
     * Bind all global kit events, which is initiated in the constructor of the class.
     *
     * @listens {window.onAbelKitPreRequest} - Fired before every AJAX call from the kit.
     * @listens {window.onAbelKitPostRequest} - Fired after every AJAX call from the kit.
     * @private
     */
    _bindEvents() {
        /**
         * Global API error event.
         *
         * @param {object} error
         * @param {string} error.type - Only AbelKitError for now.
         * @param {string} error.message - The error message payload.
         * @param {number} error.code
         */

        window.onAbelKitError = (error) => {
            this._errorHandler(error.type, error.message, error.code);
        };

        /**
         * Fired before every AJAX call coming from the kit.
         *
         * @param xhr        - The relevant xhr object from the AJAX call.
         * @param isAdmin    - Indicates whether it's an admin endpoint.
         * @param contractId - Optional explicit contract id to add to the
         *                     request headers.
         */
        window.onAbelKitPreRequest = (xhr, isAdmin = false, contractId = null) => {
            xhr.setRequestHeader('Api-Version', this.apiVersion);

            // add client id
            if (this._auth.client_id) {
                xhr.setRequestHeader('ClientId', this._auth.client_id);
            }

            // Add headers to every request if user is logged in
            if (this._user.is('loggedIn')) {
                xhr.setRequestHeader('X-TIMEZONE', this._user.get('timezone'));
                xhr.setRequestHeader('Authorization', `Bearer ${this._user.get('token')}`);

                if (this._user.is('admin') && isAdmin && !contractId) {
                    // get appropriate contract id for admin calls
                    contractId = this._user.get('contracts').getActive().id;
                }
            }

            if (contractId) {
                // add contract id to the header
                xhr.setRequestHeader('X-CONTRACTID', contractId);
            }
        };

        /**
         * Fired after every AJAX call from the kit, only fired when no error occurred.
         *
         * @param response {Object} - JSON response data.
         */
        window.onAbelKitPostRequest = (response) => {
        };
    }

    /**
     * Init app (not needed for admin login)
     */
    init(client_id, client_secret) {
        this._auth = new Auth(this._user, client_id, client_secret);

        if (this._auth.tryLoginFromCookies())
            return true;
        else
            return false;
    }

    /**
     *
     * @returns {Promise}
     */
    tryLoginFromCookies() {
        return this._auth.tryLoginFromCookies();
    }

    login(client_id, client_secret, username, password, callback, errorCallback) {
        this._auth.login(
            client_id, client_secret, username, password,
            (data) => {
                if (typeof this._loginHandler) {
                    this._loginHandler(data);
                }

                if (typeof callback === 'function') {
                    callback(data);
                }
            },
            (error, code) => {
                // Call both the global error handler and the argument
                const type = 'Login';

                this._requestErrorHandler(type, error, code);

                if (typeof errorCallback === 'function') {
                    errorCallback(type, error);
                }
            }
        );
    }

    loginWithTokens(data) {
        this._auth.loginWithTokens(data.access_token, data.refresh_token, data.expires_in)
    }

    logout(callback, errorCallback) {
        this._auth.logout(
            () => {
                if (typeof this._logoutHandler) {
                    this._logoutHandler();
                }

                if (typeof callback === 'function') {
                    callback();
                }
            },
            (error) => {
                // Call both the global error handler and the argument
                const type = 'Logout';

                this._requestErrorHandler(type, error);

                if (typeof errorCallback === 'function') {
                    errorCallback(type, error);
                }
            }
        );
    }

    /**
     * Log the admin in with Firebase.
     *
     * @param {function(callback: function, errorCallback:function)} callback - Success callback, which contains 1 argument: data (object, admin user data).
     * @param {function(callback: function, errorCallback:function)} errorCallback - The error callback function, which contains 2 arguments: type (string), message (string).
     */
    adminLogin(callback, errorCallback) {
        const clientId = this._auth && this._auth.hasOwnProperty('client_id') ? this._auth.client_id : null;

        //todo: refactor
        this._admin = this._user;
        this._auth = new AuthAdmin(this._admin, clientId);

        this._auth.login(
            (data) => {
                if (typeof this._loginHandler) {
                    this._loginHandler(data);
                }

                if (typeof callback === 'function') {
                    callback(data);
                }
            },
            (error, code) => {
                // Call both the global error handler and the argument
                const type = 'Login';

                this._requestErrorHandler(type, error, code);

                if (typeof errorCallback === 'function') {
                    errorCallback(type, error);
                }
            }
        );
    }

    /**
     * Log the admin out.
     *
     * @param {function} callback - Success callback, which contains no arguments.
     * @param {function(type: string, message: string)} errorCallback - The error callback function, which contains 2 arguments: type (string), message (string).
     */
    adminLogout(callback, errorCallback) {
        this._auth.logout(
            () => {
                if (typeof this._logoutHandler) {
                    this._logoutHandler();
                }

                if (typeof callback === 'function') {
                    callback();
                }
            },
            (error) => {
                // Call both the global error handler and the argument
                const type = 'Logout';

                this._requestErrorHandler(type, error);

                if (typeof errorCallback === 'function') {
                    errorCallback(type, error);
                }
            }
        );
    }

    /**
     * Set the contract id to be sent in every request. This changes the client we connect to.
     *
     * @returns {boolean}
     */
    setContract(id) {
        const contracts = this._user.get('contracts');
        const setContract = contracts && contracts.setActive(id);

        return !!setContract;
    }

    /**
     * Get the base url of the API.
     *
     * @returns {string}
     */
    getServer() {
        return Request.getServer();
    }

    /**
     * Middleware call with global event handlers attached. This is called by methods in services.
     *
     * @param {string} method - Middleware method url. Example: /Customer/12
     * @param {object} [data=null] - Object of data to be sent to the method url. Not for GET calls.
     * @param {function(data: object)} [callback=null] - Success callback, which contains 1 argument: data (object, response).
     * @param {function(error: object)} [errorCallback=null] - The error callback function, which contains an object with 2 properties: type (string), message (string).
     */
    call(method, data = null, callback = null, errorCallback = null, requestType = null) {
        this._auth.getValidToken(
            token => {
                new Request(method, data,
                    (response) => {
                        if (typeof callback === 'function') {
                            callback(response);
                        }

                        this._requestFinishedHandler(response);
                    },
                    (error, code) => {
                        // Call both the global error handler and the argument
                        const type = 'MiddlewareCall';

                        this._requestErrorHandler(type, error, code);

                        if (typeof errorCallback === 'function') {
                            errorCallback({type: type, message: error, code: code});
                        }
                    }, requestType);
            },
            (message, code) => {
                errorCallback({type: 'MiddlewareCall', message, code});
            }
        );
    }

    callNoAuth(method, data = null, callback = null, errorCallback = null, requestType = null) {
        new Request(method, data, (response) => {
            if (typeof callback === 'function') {
                callback(response);
            }

            this._requestFinishedHandler(response);
        }, (error, code) => {
            // Call both the global error handler and the argument
            const type = 'MiddlewareCall';

            this._requestErrorHandler(type, error, code);

            if (typeof errorCallback === 'function') {
                errorCallback({type: type, message: error, code: code});
            }
        }, requestType);
    }

    /**
     * Global kit login success handler for AJAX calls.
     *
     * @param {function(adminData: object)} callback - The success callback function, which contains 1 argument with the logged in admin user data.
     */
    onLogin(callback) {
        this._loginHandler = callback;
    }

    /**
     * Global kit logout success handler for AJAX calls.
     *
     * @param {function} callback - The success callback function, which contains no arguments.
     */
    onLogout(callback) {
        this._logoutHandler = callback;
    }

    /**
     * Global kit request success handler for AJAX calls.
     *
     * @param {function(response: object)} callback - The request success callback function to be called.
     */
    onRequestFinished(callback) {
        this._requestFinishedHandler = callback;
    }

    /**
     * Global kit request error handler for AJAX calls.
     *
     * @param {function(type: string, message: string, code: number)} callback - The request error callback function to be called.
     */
    onRequestError(callback) {
        // Arguments: type, message, code
        this._requestErrorHandler = callback;
    }

    /**
     * Global AbelKitError error handler for all errors.
     *
     * @param {function(type: string, message: string, code: number)} callback - The request error callback function to be called.
     */
    onError(callback) {
        // Arguments: type, message, code
        this._errorHandler = callback;
    }

    /**
     * Return the AbelService class.
     *
     * @returns {AbelService} AbelService
     */
    AbelService() {
        return this._service('AbelService');
    }

    /**
     * Return the AbelSecureService class.
     *
     * @returns {AbelSecureService} AbelSecureService
     */
    AbelSecureService() {
        return this._service('AbelSecureService');
    }

    /**
     * Return the AbelAdminService class.
     *
     * @returns {AbelAdminService} AbelAdminService
     */
    AbelAdminService() {
        return this._service('AbelAdminService');
    }

    /**
     * Dynamically load an object - this is the only way to import objects in ES5.
     *
     * @param {string} object - The name of the kit object to load.
     * @returns {Object}
     * @throws {Error} - Throw error if given object does not exist in the current kit.
     */
    loadObject(object) {
        if (typeof Objects[object] === 'object') {
            return Objects[object];
        } else {
            throw new AbelKitError(`Invalid loaded object: ${object}`);
        }
    }

    /**
     * Return Service class from service name.
     *
     * @param {string} name - The service name to be called.
     * @returns {AbelService|AbelSecureService|AbelAdminService}
     * @private
     * @throws {Error} - Throw error when admin is not logged in.
     * @todo Dynamic class loading
     */
    _service(name) {
        switch (name) {
            case 'AbelService':
                return new AbelService(this);
                break;

            case 'AbelSecureService':
                return new AbelSecureService(this);
                break;

            case 'AbelAdminService':
                return new AbelAdminService(this);
                break;

            default:
                throw new AbelKitError(`Class ${name} is not a service`);
        }
    }
}
