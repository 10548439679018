import BaseObject from '../BaseObject';
import AKLocationType from './AKLocationType';
import AKXYCoord from './AKXYCoord';
import AKGeoType from './AKGeoType';

/**
 * @param {AKLocationType} locationType
 * @param {AKXYCoord} coordinate
 * @param {string} [street]
 * @param {string} [houseNumber]
 * @param {string} [zip]
 * @param {string} [place]
 * @param {string} [additional]
 * @param {string} [municipality]
 * @param {string} [countryCode]
 * @param {string} [POIName]
 * @param {string} [POIAbbrevation]
 * @param {AKGeoType} geoType
 */
export default class AKLocationBase extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'locationType'  : ['LocationType', AKLocationType],
            'coordinate'    : ['Coord', AKXYCoord],
            'street'        : ['Street', 'string', false],
            'houseNumber'   : ['HouseNumber', 'string', false],
            'zip'           : ['Zip', 'string', false],
            'place'         : ['Place', 'string', false],
            'additional'    : ['Additional', 'string', false],
            'municipality'  : ['Municipality', 'string', false],
            'region'        : ['Region', 'string', false],
            'countryCode'   : ['CountryCode', 'string', false],
            'POIName'       : ['POIName', 'string', false],
            'POIAbbrevation': ['POIAbbrevation', 'string', false],
            'geoType'       : ['GeoType', AKGeoType]
        };
    }

    get address() {
        var ret = '';
        if (this.street != undefined && this.street != '' && this.houseNumber != undefined && this.houseNumber != '') {
            ret = this.street + ' ' + this.houseNumber;
        } else if (this.POIName != undefined && this.POIName != '') {
            ret = this.POIName;
        } else if (this.street != undefined && this.street != '') {
            ret = this.street;
        }

        if (this.place != undefined && this.place != '') {
            if (ret != '') ret = ret + ', ';
            ret = ret + this.place;
        }
        if (ret == '') ret = this.coordinate.y + ', ' + this.coordinate.x;

        return ret;
    }
}
