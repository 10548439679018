import BaseObject from '../BaseObject';

/**
 * @param {string} awsMysqlDatabase
 * @param {string} awsMysqlPassword
 * @param {string} awsMysqlServer
 * @param {string} awsMysqlUser
 */
export default class AKASettingsApps extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'awsMysqlDatabase'  : ['awsMysqlDatabase', 'string'],
            'awsMysqlPassword'  : ['awsMysqlPassword', 'string'],
            'awsMysqlServer'    : ['awsMysqlServer', 'string'],
            'awsMysqlUser'      : ['awsMysqlUser', 'string']
        };
    }
}
