import BaseObject from '../BaseObject';
import AKTimeWindows from '../Trip/AKTimeWindows';

/**
 * @param {number} maximumDetourIntervalMinutes
 * @param {number} notificationIntervalMinutes
 * @param {AKTimeWindows} timeWindows
 */
export default class AKATripFlexibility extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'maximumDetourIntervalMinutes'  : ['MaximumDetourIntervalMinutes', 'integer'],
            'notificationIntervalMinutes'   : ['NotificationIntervalMinutes', 'integer'],
            'timeWindows'                   : ['TimeWindows', AKTimeWindows],
            'pickupTime'                    : ['PickupTime', 'date'],
            'deliveryTime'                  : ['DeliveryTime', 'date'],
        };
    }
}
