import BaseObject from '../BaseObject';
import AKDiscount from '../Payment/AKDiscount';

/**
 * @param {boolean} active
 * @param {string} discountName
 * @param {number[]} linkedPromocodes
 * @param {string} maxUsage
 * @param {boolean} newCustomersOnly
 * @param {string} segment
 * @param {AKDiscount} discount
 */
export default class AKAPromocode extends BaseObject {
    constructor(data = {}, hasServerSpecification = false) {
        super(data, hasServerSpecification);
    }

    mapData() {
        return {
            'active'            : ['Active', 'boolean'],
            'discountName'      : ['DiscountName', 'string'],
            'linkedPromocodes'  : ['LinkedPromocodes', ['integer']],
            'maxUsage'          : ['MaxUsage', 'integer'],
            'newCustomersOnly'  : ['NewCustomersOnly', 'boolean'],
            'segment'           : ['Segment', 'string'],
            'discount'          : ['Json.Discount', AKDiscount]
        };
    }
}
